import { useMemo } from 'react';
import {
    useCrossAreaSearchBarInputValue,
    useShowAreaPanel,
    useShowCrossAreaMessagePanel,
    useShowSearchBar,
    useStaredAreas,
    useWindowWidth
} from 'shared-state/display-options/hooks';
import { useCurrentAreaId, useCurrentOrgId } from 'shared-state/location/hook';
import { useAreaListWithCount } from 'shared-state/directory/hooks';
import { useNavigate } from 'react-router';
import { SetterOrUpdater } from 'recoil';
export interface IAreaSwitchState {
    currentAreaId?: string;
    areaOptions: {
        key: string;
        title: string;
    }[];
    setShowAreaPanel: SetterOrUpdater<boolean>;
    onClickOption: (e: any, k: string | null) => void;
    handleSearchPersonIconClick: (event: any) => void;
}

export const useAreaSwitchState: (color?: string) => IAreaSwitchState = (color) => {
    const routeHistory = useNavigate();
    const [, setShowAreaPanel] = useShowAreaPanel();
    const [orgId] = useCurrentOrgId();
    const [currentAreaId] = useCurrentAreaId();
    const [staredAreaIds] = useStaredAreas(orgId || 'default');
    const areasWithCount = useAreaListWithCount();

    const areaOptions = useMemo(
        () =>
            areasWithCount
                ?.filter((area: any) => staredAreaIds.includes(area.id))
                .map((area: any) => ({ key: area.id, title: area.name, usersAndVisitorsCount: area.count })),
        [areasWithCount, staredAreaIds]
    );
    const [showSearchBar, setShowSearchBar] = useShowSearchBar();
    const [, setShowCrossAreaMessagePanel] = useShowCrossAreaMessagePanel();
    const [, setCrossAreaSearchBarInputValue] = useCrossAreaSearchBarInputValue();
    const { smallDevice, mediumDevice } = useWindowWidth();

    const onClickOption = (e: any, k: string | null) => {
        if (currentAreaId && currentAreaId != 'null' && !confirm('Are you sure you want to leave this area?')) return;
        k && routeHistory('/areas/' + k);
    };

    const handleSearchPersonIconClick = (event) => {
        // stops the event propagation using event.stopPropagation() to prevent the click event from reaching the document click event listener.
        event.stopPropagation();
        if (smallDevice || mediumDevice) {
            setShowCrossAreaMessagePanel(true);
            setShowSearchBar(false);
        } else {
            setShowSearchBar(!showSearchBar);
            setShowCrossAreaMessagePanel(false);
        }

        setCrossAreaSearchBarInputValue('');
    };

    return {
        currentAreaId,
        areaOptions,
        color,
        setShowAreaPanel,
        onClickOption,
        handleSearchPersonIconClick
    };
};
