/* eslint-disable @typescript-eslint/no-unused-vars */
import AlertModal from 'components/AlertModals/AlertModal';
import IvicosStrings from 'kits/language/stringKit';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { CLIENT_EMIT_USER_PROFILE, RECONNECT, RECONNECT_ATTEMPT, RECONNECT_ERROR, RECONNECT_FAILED } from 'services/socket-connection/events';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useSocket } from '../hooks';
import { SocketContextProvider, defaultSocketContextState, socketReducer } from './SocketContext';
import { useBoolean } from '@uifabric/react-hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISocketProviderProps {
    children?: React.ReactNode;
}

const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: 'ivAccessToken',
    LAST_AREA_ID: 'lastAreaId',
    LOCAL_ID: 'localID'
};

const SocketProvider: React.FunctionComponent<ISocketProviderProps> = ({ children }) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const lastAreaId = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_AREA_ID);

    const webSocketBase = process.env.REACT_APP_WS_BASE;

    if (!token || !webSocketBase) {
        console.error('WebSocket connection cannot be established. Missing token or WebSocket base URL.');
        return null;
    }

    const socket = useSocket(webSocketBase, {
        extraHeaders: {
            Authorization: token
        },
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        autoConnect: false
    });
    const [SocketState, SocketDispatch] = useReducer(socketReducer, defaultSocketContextState);
    const localProfile = useLocalProfile();
    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    const startListeners = useCallback(() => {
        socket.io.on(RECONNECT, (attempt: any) => {
            console.info(`Reconnected on attempt ${attempt}`);
            localProfile && sendUserInfoToSocketIO();
        });
        socket.io.on(RECONNECT_ATTEMPT, (attempt: any) => console.warn(`Reconnection attempt ${attempt}`));
        socket.io.on(RECONNECT_FAILED, () => {
            console.error('Reconnection failure');
            toggleAlert();
        });

        return () => {
            socket.io.off(RECONNECT);
            socket.io.off(RECONNECT_ATTEMPT);
            socket.io.off(RECONNECT_FAILED);
        };
    }, [socket, localProfile]);

    const sendUserInfoToSocketIO = useCallback(() => {
        // console.log('sending user info to the server...🚀');
        if (!localProfile || !socket) {
            console.error('Socket connection cannot be established. Missing local profile or socket.');
            return;
        }
        if (!localProfile.room?.includes('undefined')) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.LOCAL_ID, localProfile.uid);
        }
        socket.emit(CLIENT_EMIT_USER_PROFILE, { localProfile, lastAreaId });
    }, [localProfile, socket]);

    useEffect(() => {
        // connect to the websocket
        socket.connect();

        // save the socket in the context
        SocketDispatch({ type: 'UPDATE_SOCKET', payload: socket });

        // listen default connection event
        const cleanupListeners = startListeners();

        return () => {
            cleanupListeners();

            if (socket.connected) {
                socket.disconnect();
            }
        };
    }, [socket, localProfile]);

    useEffect(() => {
        localProfile && sendUserInfoToSocketIO();
    }, [localProfile]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                console.warn('user changed tab');
                socket.emit('client-emit-user-status', { status: 'tab-hidden' });
            } else {
                console.warn('user back to tab');
                socket.emit('client-emit-user-status', { status: 'tab-visible' });
            }
        };

        const handleOnlineStatus = () => {
            console.warn('user is online');
            socket.emit('client-emit-user-status', { status: 'online' });

            // hard reload the page
            window.location.replace(window.location.href);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('online', handleOnlineStatus);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('online', handleOnlineStatus);
        };
    }, [socket]);

    return (
        <SocketContextProvider value={{ SocketState, SocketDispatch }}>
            {displayAlert && <AlertModal title={IvicosStrings.generalAlertTitle} subText={IvicosStrings.webSocketAlert} toggleAlert={toggleAlert} />}
            {children}
        </SocketContextProvider>
    );
};

export default SocketProvider;
