import { Stack, TextField, Label, DirectionalHint, Callout, Text, DefaultButton, PrimaryButton, Icon, IconButton } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import { useState } from 'react';
import React from 'react';
import { idpService } from 'services/api/identity-provider.service';

export interface ISherloqFormFields {
    clientId: string;
    clientSecret: string;
    model: string;
    customer: string;
}

const sherloqFormFields = (changes: ISherloqFormFields) => {
    return [
        {
            key: 'clientId',
            label: 'Client ID',
            value: changes.clientId,
            type: 'text',
            callout: IvicosStrings.sherloqClientIdCallout
        },
        {
            key: 'clientSecret',
            label: 'Client secret',
            value: changes.clientSecret,
            type: 'text',
            callout: IvicosStrings.sherloqClientSecretCallout
        },
        {
            key: 'model',
            label: 'Model',
            value: changes.model,
            type: 'text',
            callout: IvicosStrings.sherloqModelCallout
        },
        {
            key: 'customer',
            label: 'Customer',
            value: changes.customer,
            type: 'text',
            callout: IvicosStrings.sherloqCustomerCallout
        }
    ];
};

interface ISherloqSettingsFormProps {
    setIsSherloqActive: React.Dispatch<React.SetStateAction<boolean>>;
    handleSherloqSettingPanel: (value: boolean) => void;
    sherloqSettingsFormData: ISherloqFormFields;
    setSherloqSettingsFormData: React.Dispatch<React.SetStateAction<ISherloqFormFields>>;
    listOfExamplePrompts: string[];
    setListOfExamplePrompts: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SherloqSettingsForm: React.FC<ISherloqSettingsFormProps> = ({
    setIsSherloqActive,
    handleSherloqSettingPanel,
    sherloqSettingsFormData,
    setSherloqSettingsFormData,
    listOfExamplePrompts,
    setListOfExamplePrompts
}) => {
    const [visibleCalloutId, setVisibleCalloutId] = useState<string | null>(null);
    const [isExamplePromptCalloutVisible, setIsExamplePromptCalloutVisible] = useState(false);
    const [newPrompt, setNewPrompt] = useState('');
    const [isSherloqTestPassed, setIsSherloqTestPassed] = useState(false);
    const [isSherloqTestLoading, setIsSherloqTestLoading] = useState(false);
    const [isSherloqTestResultVisible, setIsSherloqTestResultVisible] = useState(false);
    const [saveButtonState, setSaveButtonState] = useState<'save' | 'saving' | 'saved'>('save');

    const handleAddPrompt = () => {
        setListOfExamplePrompts([...listOfExamplePrompts, newPrompt]);
        setNewPrompt('');
    };

    const handleRemovePrompt = (indexToRemove: number) => {
        setListOfExamplePrompts((prev) => prev.filter((_, index) => index !== indexToRemove));
    };

    const handleChange = (key: keyof ISherloqFormFields, value: string) => {
        setIsSherloqTestResultVisible(false);
        setSherloqSettingsFormData((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const renderValidationIcon = (isValid: boolean) => {
        return (
            <div
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    border: `2px solid ${isValid ? '#107C10' : '#A80000'}`,
                    // backgroundColor: isValid ? '#DFF6DD' : '#FDE7E9',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 8
                }}
            >
                <Icon
                    iconName={isValid ? 'CheckMark' : 'Cancel'}
                    styles={{
                        root: {
                            fontSize: 14,
                            fontWeight: 600,
                            color: isValid ? '#107C10' : '#A80000'
                        }
                    }}
                />
            </div>
        );
    };

    const fields = sherloqFormFields(sherloqSettingsFormData);
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {fields.map((field) => {
                const calloutId = useId('callout-' + field.key);

                return (
                    <Stack key={field.key} tokens={{ childrenGap: 4 }}>
                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                            <Label>{field.label}</Label>
                            <IC variant="custom" size={15} style={{ cursor: 'pointer', position: 'absolute', right: '8%' }}>
                                <QuestionCircle onClick={() => setVisibleCalloutId(visibleCalloutId === calloutId ? null : calloutId)} id={calloutId} />
                            </IC>

                            {visibleCalloutId === calloutId && (
                                <Callout
                                    target={`#${calloutId}`}
                                    onDismiss={() => setVisibleCalloutId(null)}
                                    directionalHint={DirectionalHint.leftTopEdge}
                                    styles={{
                                        root: {
                                            width: 320,
                                            padding: '10px 14px'
                                        }
                                    }}
                                >
                                    <Text>{field.callout}</Text>
                                </Callout>
                            )}
                        </Stack>
                        <TextField
                            value={field.value}
                            onChange={(_, newValue) => handleChange(field.key as keyof ISherloqFormFields, newValue || '')}
                            data-test-id={field.key}
                        />
                    </Stack>
                );
            })}

            <Stack tokens={{ childrenGap: 8 }}>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Label>{IvicosStrings.sherloqExamplePrompts}</Label>
                    <Stack style={{ width: '20px', position: 'relative' }}>
                        <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                            <QuestionCircle onClick={() => setIsExamplePromptCalloutVisible(!isExamplePromptCalloutVisible)} id="prompts-callout" />
                        </IC>
                        {isExamplePromptCalloutVisible && (
                            <Callout
                                target="#prompts-callout"
                                onDismiss={() => setIsExamplePromptCalloutVisible(false)}
                                directionalHint={DirectionalHint.leftTopEdge}
                                styles={{
                                    root: {
                                        width: 320,
                                        padding: '10px 14px'
                                    }
                                }}
                            >
                                <Text>{IvicosStrings.sherloqexamplePromptsCallout}</Text>
                            </Callout>
                        )}
                    </Stack>
                </Stack>

                <TextField
                    value={newPrompt}
                    onChange={(_, value) => setNewPrompt(value || '')}
                    onKeyDown={(ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
                        if (ev.key === 'Enter' && !ev.shiftKey) {
                            ev.preventDefault();
                            handleAddPrompt();
                        }
                    }}
                    multiline
                    rows={3}
                    placeholder={IvicosStrings.sherloqprompsPlaceholder}
                    disabled={listOfExamplePrompts.length >= 3}
                />

                {/* Display Added Prompts */}
                <Stack tokens={{ childrenGap: 4 }}>
                    {listOfExamplePrompts?.map((prompt, index) => (
                        <Stack
                            key={index}
                            horizontal
                            verticalAlign="center"
                            styles={{
                                root: {
                                    backgroundColor: '#F3F2F1',
                                    padding: '4px',
                                    borderRadius: '30px',
                                    height: 'auto'
                                }
                            }}
                        >
                            <IconButton
                                iconProps={{ iconName: 'Cancel' }}
                                styles={{
                                    icon: {
                                        fontSize: 12,
                                        color: '#605E5C',
                                        ':hover': {
                                            color: '#A80000',
                                            fontWeight: 600
                                        }
                                    }
                                }}
                                onClick={() => handleRemovePrompt(index)}
                                data-test-id="remove-prompt-button"
                            />
                            <Text style={{ fontSize: 14, marginTop: -4 }}>{prompt}</Text>
                        </Stack>
                    ))}
                </Stack>
            </Stack>

            {/* Display Button */}
            <Stack tokens={{ childrenGap: 16 }} style={{ position: 'absolute', bottom: 25, right: '5%', left: '5%', width: '90%' }}>
                <Stack>
                    <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign="center">
                        {isSherloqTestLoading ? (
                            <DefaultButton
                                iconProps={{ iconName: 'TestBeakerSolid' }}
                                styles={{
                                    root: {
                                        width: '55%',
                                        padding: '10px',
                                        border: 'none',
                                        color: 'green'
                                    },
                                    rootHovered: {
                                        backgroundColor: 'none',
                                        color: 'green'
                                    }
                                }}
                                text={IvicosStrings.sherloqTestLoading}
                            />
                        ) : (
                            <DefaultButton
                                iconProps={{ iconName: 'TestBeakerSolid' }}
                                style={{
                                    width: '55%',
                                    padding: '10px'
                                }}
                                text={IvicosStrings.sherloqTest}
                                onClick={async () => {
                                    setIsSherloqTestLoading(true);
                                    try {
                                        const result = await idpService.configureSherloqSettings(
                                            sherloqSettingsFormData.customer,
                                            sherloqSettingsFormData.model,
                                            sherloqSettingsFormData.clientId,
                                            sherloqSettingsFormData.clientSecret,
                                            true,
                                            listOfExamplePrompts.length > 0 ? listOfExamplePrompts : undefined
                                        );

                                        setIsSherloqTestPassed(result.is_test_successful);
                                    } catch (error: any) {
                                        console.error({ error: error.response });
                                        setIsSherloqTestPassed(false);
                                    }

                                    setIsSherloqTestLoading(false);
                                    setIsSherloqTestResultVisible(true);
                                }}
                            />
                        )}

                        {isSherloqTestResultVisible && <Stack style={{ marginLeft: 10 }}>{renderValidationIcon(isSherloqTestPassed)}</Stack>}
                    </Stack>
                    {isSherloqTestResultVisible ? (
                        <>
                            {isSherloqTestPassed ? (
                                <Text style={{ color: '#107C10' }}>{IvicosStrings.sherloqTestSuccess}</Text>
                            ) : (
                                <Text style={{ color: '#A80000' }}>{IvicosStrings.sherloqTestError}</Text>
                            )}
                        </>
                    ) : null}
                </Stack>

                <PrimaryButton
                    style={{ width: '55%', padding: '10px' }}
                    text={IvicosStrings.talkToSales}
                    onClick={() => {
                        handleSherloqSettingPanel(false);
                        setTimeout(() => {
                            window.location.href = 'mailto:sales@ivicos.eu';
                        }, 500);
                    }}
                />
                <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="space-around" style={{ marginTop: 16 }}>
                    <DefaultButton
                        style={{ width: '50%', padding: '10px' }}
                        text={IvicosStrings.cancelActionTitle}
                        onClick={() => {
                            handleSherloqSettingPanel(false);
                        }}
                    />
                    <PrimaryButton
                        style={{ width: '50%', padding: '10px' }}
                        text={
                            saveButtonState === 'save'
                                ? IvicosStrings.saveActionTitle
                                : saveButtonState === 'saving'
                                ? IvicosStrings.savingActionLoadingTitle
                                : IvicosStrings.savingActionSuccessTitle
                        }
                        onClick={async () => {
                            setSaveButtonState('saving');
                            try {
                                const result = await idpService.configureSherloqSettings(
                                    sherloqSettingsFormData.customer,
                                    sherloqSettingsFormData.model,
                                    sherloqSettingsFormData.clientId,
                                    sherloqSettingsFormData.clientSecret,
                                    false,
                                    listOfExamplePrompts.length > 0 ? listOfExamplePrompts : undefined
                                );

                                setIsSherloqActive(result.is_sherloq_active);
                                setSaveButtonState('saved');
                            } catch (error: any) {
                                await idpService.deactivateSherloq();
                                setIsSherloqActive(false);
                                setSaveButtonState('save');
                            }

                            setTimeout(() => {
                                handleSherloqSettingPanel(false);
                                setSaveButtonState('save');
                            }, 300);
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
