import React from 'react';

import { useRoomSelectionDialogState } from './RoomSelectionDialog.state';
import { RoomSelectionDialogView } from './RoomSelectionDialog.view';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { RoomSelectionDialogMobile } from './RoomSelectionDialogMobile.view';
export const RoomSelectionDialog: React.FC<{}> = () => {
    const state = useRoomSelectionDialogState();
    const { smallDevice, mediumDevice } = useWindowWidth();
    return smallDevice || mediumDevice ? <RoomSelectionDialogMobile {...state} /> : <RoomSelectionDialogView {...state} />;
};
