import { DefaultButton, Image, Shimmer, Stack, Text } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import sherloqImage from 'media/img/SHERLOQ.png';
import { InputField, Suggestions } from './children/Input';
import IvicosStrings from 'kits/language/stringKit';
import { Error } from './children/Error';
import { getSuggestedPrompts, makeRequest } from './Actions';

enum SherloqWidgetMode {
    Input,
    Loading,
    Result,
    Error
}

export const SherloqWidget: React.FC = () => {
    const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);

    useEffect(() => {
        const fetchPrompts = async () => {
            const prompts = await getSuggestedPrompts();
            setSuggestedPrompts(prompts);
        };

        fetchPrompts();
    }, []);

    const [mode, setMode] = useState(SherloqWidgetMode.Input);
    const [prompt, setPrompt] = useState('');
    const [result, setResult] = useState('');

    async function handleSubmit(submittedPrompt: string) {
        setPrompt(submittedPrompt);
        setMode(SherloqWidgetMode.Loading);
        try {
            const response = await makeRequest(submittedPrompt);
            setResult(response);
            setMode(SherloqWidgetMode.Result);
        } catch (e: any) {
            console.error(e);
            setMode(SherloqWidgetMode.Error);
        }
    }

    function handleReset() {
        setMode(SherloqWidgetMode.Input);
        setPrompt('');
        setResult('');
    }

    function renderWidgetBody() {
        switch (mode) {
            case SherloqWidgetMode.Input:
                return <InputView onSubmit={handleSubmit} suggestedPrompts={suggestedPrompts} />;
            case SherloqWidgetMode.Loading:
                return <LoadingView />;
            case SherloqWidgetMode.Result:
                return <ResultView result={result} onReset={handleReset} />;
            default:
                return <ErrorView onReset={handleReset}></ErrorView>;
        }
    }

    return (
        <Stack style={{ height: '100%' }} tokens={{ childrenGap: '8px' }} aria-label="Sherloq Widget">
            <Stack.Item grow={0} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Text variant="xLarge">{result ? IvicosStrings.sherloqTitleSherloqAnswered : IvicosStrings.sherloqTitleAskSherloq}</Text>
                <Image src={sherloqImage} height={'30px'} />
            </Stack.Item>
            {prompt && (
                <Text>
                    {IvicosStrings.sherloqSubtitlePrompt}
                    <span style={{ fontWeight: 'bold' }}>{prompt}</span>
                </Text>
            )}
            <Stack.Item grow={1} style={{ display: 'flex', alignItems: 'end' }}>
                {renderWidgetBody()}
            </Stack.Item>
        </Stack>
    );
};

export interface InputViewProps {
    onSubmit: (phrase: string) => void;
    suggestedPrompts: string[];
}

export const InputView: React.FC<InputViewProps> = (props) => {
    const { onSubmit, suggestedPrompts } = props;

    return (
        <Stack style={{ height: '100%', width: '100%' }} tokens={{ childrenGap: '8px' }} className="sherloq-widget">
            <Stack.Item grow={0}>
                <Text>{IvicosStrings.sherloqSubtitleSuggestions}</Text>
            </Stack.Item>
            <Stack.Item grow={1} style={{ display: 'flex', alignItems: 'end' }}>
                <Suggestions suggestedPrompts={suggestedPrompts} onSubmit={onSubmit}></Suggestions>
            </Stack.Item>
            <Stack.Item grow={0}>
                <InputField onSubmit={onSubmit}></InputField>
            </Stack.Item>
        </Stack>
    );
};

const LoadingView: React.FC = () => {
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Shimmer style={{ marginBottom: '8px' }} />
            <Shimmer style={{ marginBottom: '8px' }} />
            <Shimmer style={{ marginBottom: '8px' }} />
            <Shimmer width="75%" />
        </div>
    );
};

const ResultView: React.FC<{ result: string; onReset: () => void }> = (props) => {
    const { result, onReset } = props;
    return (
        <Stack style={{ height: '100%' }} tokens={{ childrenGap: '8px' }} verticalAlign="end">
            <Stack.Item grow={0}>
                <Text className="sherloq-answer">{result}</Text>
            </Stack.Item>
            <Stack.Item grow={1}>
                <DefaultButton className="sherloq-answer-back" onClick={onReset}>
                    {IvicosStrings.sherloqButtonNewRequest}
                </DefaultButton>
            </Stack.Item>
            <Stack.Item grow={0}>
                <Text variant="smallPlus">{IvicosStrings.sherloqLabelDisclaimerSources}</Text>
            </Stack.Item>
        </Stack>
    );
};

export const ErrorView: React.FC<{ errorMessage?: string; onReset: () => void }> = (props) => {
    return (
        <Stack style={{ height: '100%', width: '100%' }} verticalAlign="center">
            <Error onReset={props.onReset} errorMessage={props.errorMessage}></Error>
        </Stack>
    );
};
