import { getTheme, ITheme, Stack, TooltipHost } from '@fluentui/react';
import { SharedColors } from '@uifabric/fluent-theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { AreaDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { IC } from 'components/SVGIcon';
import 'intro.js/introjs.css';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import { sendEvent } from 'kits/eventKit';
import { Star, StarFilled } from 'kits/IconKit2';
import { useSession } from 'kits/sessionKit';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useMemo, useState } from 'react';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { getClassNames } from 'styles/responsiveness';
import { useCurrentOrgBranding } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IAreaCardStateProps {
    theme: ITheme;
    getPrimaryCardColor: () => string;
    stepsEnabled?: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    steps: {
        initialStep: number;
        steps: (
            | {
                  element: string;
                  intro: string;
                  position: string;
                  tooltipClass: string;
                  showProgress?: undefined;
                  showBullets?: undefined;
              }
            | {
                  element: string;
                  intro: string;
                  position: string;
                  showProgress: boolean;
                  showBullets: boolean;
                  tooltipClass?: undefined;
              }
        )[];
    };
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
    areaCardWidth: string;
    areaCardWidthVertical: string;
    area?: AreaDto;
    descriptionId: string;
    labelId: string;
    buttonId: string;
    identityClaims?: IIdentityClaims;
    isCalloutVisible: boolean;
    toggleIsCalloutVisible: () => void;
    msTeam: any;
    renderAreaActions: () => JSX.Element;
    color: string | undefined;
    usersAndVisitorsCount: { users: number; visitors: number };
}

export const useAreaCardState: (area: any, onStar: any) => IAreaCardStateProps = (area, onStar) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [identityClaims, setIdentityClaims] = useIdentityClaims();
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const [orgBranding] = useCurrentOrgBranding();
    const isPublic = area.attributes.variant == 'DEFAULT';
    const [stepsEnabled, setStepsEnabled] = useState<boolean>();
    const { areaCardWidth, areaCardWidthVertical } = getClassNames();
    const [msTeam, setMsTeam] = useState<any>();
    const [msToken] = useSession('@msAccessToken');
    const color = orgBranding?.orgColors[0];

    const theme = getTheme();
    const { smallDevice } = useWindowWidth();
    const getPrimaryCardColor = () => (isPublic ? orgBranding?.orgColors[0] || IvicosColors.Mittelblau : SharedColors.blueMagenta10);

    useEffect(() => {
        if (localStorage.getItem('intro-areas') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-areas') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const usersAndVisitorsCount = useMemo(() => {
        return area.attributes.usersAndVisitorsCount;
    }, [area]);

    const steps = {
        initialStep: 0,
        steps: [
            {
                element: '.start',
                intro: IvicosStrings.areaIntroStep1,
                position: 'middle',
                tooltipClass: 'introjs-tooltip-start'
            },
            {
                element: '.menu',
                intro: IvicosStrings.areaIntroStep2,
                position: 'right',
                showProgress: false,
                showBullets: true
            },
            {
                element: '.one-card',
                intro: IvicosStrings.areaIntroStep3,
                position: 'top',
                tooltipClass: 'introjs-tooltip-areas'
            }
        ]
    };

    const options = {
        doneLabel: 'Ok!',
        nextLabel: IvicosStrings.nextActionTitle2,
        prevLabel: IvicosStrings.back,
        stepNumbersOfLabel: IvicosStrings.of,
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    const dispatchUnauthorizedEvent = (data: any) => sendEvent('ms-unauthorized', data);

    useEffect(() => {
        if (area.attributes.variant == 'MSTEAM' && area.relationships.team) {
            const msTeamId = area.relationships.team;
            fetch(`https://graph.microsoft.com/v1.0/teams/${msTeamId}/`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + msToken
                })
            })
                .then((response: any) => {
                    if (!response.ok) {
                        response.status == 401 && dispatchUnauthorizedEvent({ response });
                        return false;
                    }

                    return response.json();
                })
                .then((teamData: any) => {
                    setMsTeam(teamData);
                })
                .catch((err) => console.log(err));
        }
    }, [area]);

    const renderAreaActions = () => {
        return (
            <TooltipHost
                content={smallDevice ? '' : (area.isStared && IvicosStrings.removeAreaFromTabBarActionTitle) || IvicosStrings.addAreaToTabBarActionTitle}
            >
                <Stack
                    onClick={() => {
                        onStar(area, !area.isStared);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {area.isStared ? (
                        <IC variant="custom" size={21} iconColor={color ? color && getTextColorForBackground(color) : 'white'}>
                            <StarFilled />
                        </IC>
                    ) : (
                        <IC variant="custom" size={21} iconColor={color ? color && getTextColorForBackground(color) : 'white'}>
                            <Star />
                        </IC>
                    )}
                </Stack>
            </TooltipHost>
        );
    };

    return {
        theme,
        stepsEnabled,
        steps,
        options,
        areaCardWidth,
        areaCardWidthVertical,
        descriptionId,
        labelId,
        buttonId,
        identityClaims,
        isCalloutVisible,
        msTeam,
        renderAreaActions,
        getPrimaryCardColor,
        setStepsEnabled,
        toggleIsCalloutVisible,
        color,
        usersAndVisitorsCount
    };
};
