import { ICrossUserProfile } from 'features/Areas/AreaSwitch/CrossAreaDirectMessaging/dataTypes';
import { atom } from 'recoil';
import { IProfile, IVisitorProfile } from 'shared-state/identity/types';

export const aUserList = atom<IProfile[]>({ key: 'aUserList', default: [] });
export const aDirectMessageUserList = atom<ICrossUserProfile[]>({ key: 'aDirectMessageUserList', default: [] });
export const aVisitorList = atom<IVisitorProfile[]>({ key: 'aVisitorList', default: [] });
export const aUsersAndVisitorsNumber = atom<{ [areaId: string]: { users: number; visitors: number } }>({
    key: 'aUsersAndVisitorsNumber',
    default: {}
});
export const aUserListForRoomSelection = atom<IProfile[]>({
    key: 'aUserListForRoomSelection',
    default: []
});
