/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useWindowWidth } from 'shared-state/display-options/hooks';

interface IWaitingAreaTimerElementProps {
    timerProps: any;
    hourSeconds: number;
    setIsTimerActive: (active: boolean) => void;
    strings: {
        waitingAreaWaitBriefly: string;
        waitingAreaHostInformed: string;
    };
}

export const WaitingAreaTimerElement: React.FC<IWaitingAreaTimerElementProps> = ({ timerProps, hourSeconds, setIsTimerActive, strings }) => {
    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <Stack horizontal={smallDevice || mediumDevice} verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 16 }}>
            {smallDevice || mediumDevice ? (
                <>
                    {/* Timer First */}
                    <Stack
                        horizontal
                        horizontalAlign="center"
                        verticalAlign="center"
                        style={{
                            borderRadius: 4
                        }}
                    >
                        <Stack style={{ alignItems: 'center', padding: 0 }} aria-label="visitor-waiting-area-timer">
                            <CountdownCircleTimer
                                {...timerProps}
                                isPlaying
                                initialRemainingTime={hourSeconds}
                                duration={hourSeconds}
                                colors="#39689c"
                                onComplete={() => setIsTimerActive(false)}
                                strokeWidth={7}
                                size={50}
                            >
                                {({ elapsedTime }) => null}
                            </CountdownCircleTimer>
                        </Stack>
                    </Stack>

                    {/* Text Second */}
                    <Stack>
                        <Text aria-label="visitor-waiting-area-wait-briefly">
                            <strong>{strings.waitingAreaWaitBriefly}</strong> {strings.waitingAreaHostInformed}
                        </Text>
                    </Stack>
                </>
            ) : (
                <>
                    {/* Text First */}
                    <Stack>
                        <Text aria-label="visitor-waiting-area-wait-briefly">
                            <strong>{strings.waitingAreaWaitBriefly}</strong> {strings.waitingAreaHostInformed}
                        </Text>
                    </Stack>

                    {/* Timer Second */}
                    <Stack
                        horizontal
                        horizontalAlign="center"
                        verticalAlign="center"
                        style={{
                            borderRadius: 4
                        }}
                    >
                        <Stack style={{ alignItems: 'center', padding: 10 }} aria-label="visitor-waiting-area-timer">
                            <CountdownCircleTimer
                                {...timerProps}
                                isPlaying
                                initialRemainingTime={hourSeconds}
                                duration={hourSeconds}
                                colors="#39689c"
                                onComplete={() => setIsTimerActive(false)}
                                strokeWidth={15}
                                size={130}
                            >
                                {({ elapsedTime }) => null}
                            </CountdownCircleTimer>
                        </Stack>
                    </Stack>
                </>
            )}
        </Stack>
    );
};
