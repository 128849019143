/* eslint-disable react/react-in-jsx-scope */
import { Stack } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { aSetTopic, aSetTopicDialog, aShowTopic } from 'features/SetTopicDialog/atom';
import {
    Attachments,
    EditIcon,
    Exit,
    GalleryView,
    Marker,
    Mic,
    MicOff,
    Settings,
    SettingsVisitor,
    ShareScreen,
    ShareScreenOff,
    SpeakerStatsBars,
    SpeakerView,
    Video,
    VideoOff
} from 'kits/IconKit2';
import SvgEtherpad from 'kits/IconKit2/Etherpad';
import RaiseHand from 'kits/IconKit2/RaiseHand';
import IvicosStrings from 'kits/language/stringKit';

import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    aHandRaiseButtonDisabled
    // , aRoundTableToggle
} from 'shared-state/directory/atoms';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import SherloqIcon from '../Sherloq/Icon';
import { useCurrentAreaId, useCurrentRoomId, useIsVisitorAtLandingPage } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';

export type ICallActionKey =
    | 'device-settings'
    | 'toggle-view'
    | 'toggle-audio'
    | 'toggle-video'
    | 'set-topic'
    | 'leave-room'
    | 'share-screen'
    | 'encrypt-room'
    | 'toggle-stats'
    | 'toggle-tldraw'
    | 'toggle-etherpad'
    | 'toggle-collaboard'
    | 'toggle-attachments'
    | 'toggle-chat'
    | 'toggle-sherloq'
    | 'raise-hand';

export interface ICallAction {
    key: ICallActionKey;
    title: string;
    icon: ReactElement;
    command?: string;
    iconVariant?: 'dark' | 'light' | 'red' | 'primary' | 'green' | 'deactivated';
    data?: { enable: boolean };
    onCommandSent?: () => void;
    onSetTopic?: () => void;
}

export interface ICallState {
    micIsMuted: boolean;
    cameraIsMuted: boolean;
    sharingIsOn: boolean;
    encryptionIsEnabled: boolean;
    addOnIsVisible: boolean;
    activeAddOn: string | undefined;
    statsAreVisible: boolean;
    tileViewIsEnabled: boolean;
    isLocalUserHandRaised: boolean;
    shouldDisableButton: boolean;
    isChatVisible: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCallActions = (callState: ICallState) => {
    const { smallDevice, mediumDevice, smallLaptop } = useWindowWidth();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        micIsMuted,
        cameraIsMuted,
        tileViewIsEnabled,
        sharingIsOn,
        // statsAreVisible,
        addOnIsVisible,
        activeAddOn,
        isLocalUserHandRaised
        // shouldDisableButton
        // isChatVisible
    } = callState;

    const routeHistory = useNavigate();
    const [currentAreaId] = useCurrentAreaId();

    const isRaiseHandButtonDisabled = useRecoilValue(aHandRaiseButtonDisabled);
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();

    let callActions: { [index: string]: ICallAction } = {};

    const chatIconSvg = (
        <svg width="24" height="21" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 0V9.14286H2.28571V12.0536L3.21429 11.3036L5.91071 9.14286H11.4286V0H0ZM1.14286 1.14286H10.2857V8H5.51786L5.35714 8.125L3.42857 9.66071V8H1.14286V1.14286ZM12.5714 2.28571V3.42857H14.8571V10.2857H12.5714V11.9464L10.4821 10.2857H6.19643L4.76786 11.4286H10.0893L13.7143 14.3393V11.4286H16V2.28571H12.5714Z"
                fill="black"
            />
        </svg>
    );

    const handWaveColorChanger = (isLocalUserHandRaised: boolean, isRaiseHandButtonDisabled: boolean) => {
        if (isRaiseHandButtonDisabled) {
            return 'deactivated';
        }
        return 'primary';
    };

    const setToggleSetTopicDialog = useSetRecoilState<any>(aSetTopicDialog);
    const setShowTopic = useSetRecoilState<boolean>(aShowTopic);
    const setTopic = useSetRecoilState<any>(aSetTopic);
    const localProfile = useLocalProfile();

    const [roomId] = useCurrentRoomId();
    const usersInRoom = typeof roomId === 'string' ? useUsersInRoom(roomId) : [];
    if (usersInRoom.length === 0) {
        return {};
    }
    const userAloneInPersonalRoom = roomId.includes('personal-') && usersInRoom.length < 2;
    const isCallActionButtonDisabled = cameraIsMuted && micIsMuted && userAloneInPersonalRoom && !sharingIsOn;
    const isButtonDisabled = isCallActionButtonDisabled && userAloneInPersonalRoom;

    if (!(smallDevice || mediumDevice || smallLaptop)) {
        callActions = {
            'device-settings': {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleVirtualBackgroundDialog', // it will open the Jitsi's settings modal
                iconVariant: isButtonDisabled ? 'deactivated' : isVisitorAtLandingPage ? 'primary' : 'dark',
                icon: isVisitorAtLandingPage ? <SettingsVisitor /> : <Settings />
            },
            'toggle-audio': {
                key: 'toggle-audio',
                title: IvicosStrings.toggleAudioActionTitle,
                command: 'toggleAudio',
                icon: micIsMuted ? <MicOff /> : <Mic />
            },
            'toggle-video': {
                key: 'toggle-video',
                title: IvicosStrings.toggleVideoActionTitle,
                command: 'toggleVideo',
                icon: cameraIsMuted ? <VideoOff /> : <Video />
            },
            'toggle-view': {
                key: 'toggle-view',
                title: IvicosStrings.toggleTileViewActionTitle,
                command: 'toggleTileView',
                iconVariant: isButtonDisabled ? 'deactivated' : 'dark',
                icon: tileViewIsEnabled ? <GalleryView /> : <SpeakerView />
            },
            'toggle-stats': {
                key: 'toggle-stats',
                title: IvicosStrings.toggleStatsActionTitle,
                command: 'toggleStats',
                iconVariant: 'primary',
                icon: <SpeakerStatsBars />
            },
            'share-screen': {
                key: 'share-screen',
                title: sharingIsOn ? IvicosStrings.shareScreenActionActiveTitle : IvicosStrings.shareScreenActionTitle,
                command: 'toggleShareScreen',
                data: { enable: !sharingIsOn },
                iconVariant: isButtonDisabled ? 'deactivated' : 'primary',
                icon: sharingIsOn ? <ShareScreenOff /> : <ShareScreen />
            },
            'toggle-chat': {
                key: 'toggle-chat',
                title: 'Open Chat',
                command: 'toggleChat',
                iconVariant: 'primary',
                icon: chatIconSvg
            },
            'toggle-sherloq': {
                key: 'toggle-sherloq',
                title: IvicosStrings.sherloqTitleAskSherloq,
                command: 'toggleSherloq',
                iconVariant: 'primary',
                icon: <SherloqIcon />
            },
            'leave-room': {
                key: 'leave-room',
                title: sharingIsOn ? IvicosStrings.alertMessageWhileScreenShareIsActive : IvicosStrings.leaveRoomActionTitle,
                onCommandSent: () => {
                    !sharingIsOn && routeHistory('/areas/' + currentAreaId);
                    if (localStorage.getItem('topicTitle')) {
                        localStorage.removeItem('topicTitle');
                    }

                    if (localStorage.getItem('roomIdWithTopic')) {
                        localStorage.removeItem('roomIdWithTopic');
                    }

                    setTopic('');
                    setShowTopic(false);
                    setTimeout(() => {
                        if (sessionStorage.getItem('whitelist-roomId')) {
                            sessionStorage.removeItem('whitelist-roomId');
                        }
                    }, 1000);
                },
                iconVariant: sharingIsOn ? 'deactivated' : 'red',
                icon: <Exit data-test-id="leave-room-icon" />
            },
            'toggle-tldraw': {
                key: 'toggle-tldraw',
                title: 'Toggle Tldraw',
                command: 'toggleAddOn-tldraw',
                iconVariant: addOnIsVisible && activeAddOn == 'tldraw' ? 'red' : 'dark',
                icon: <Marker />
            },
            'toggle-etherpad': {
                key: 'toggle-etherpad',
                title: 'Toggle Etherpad',
                command: 'toggleAddOn-etherpad',
                iconVariant: addOnIsVisible && activeAddOn == 'etherpad' ? 'red' : 'dark',
                icon: <SvgEtherpad />
            },
            'toggle-collaboard': {
                key: 'toggle-collaboard',
                title: 'Toggle Collaboard',
                command: 'toggleAddOn-collaboard',
                iconVariant: addOnIsVisible && activeAddOn == 'collaboard' ? 'red' : 'dark',
                icon: <Marker />
            },
            'toggle-attachments': {
                key: 'toggle-attachments',
                title: IvicosStrings.toggleAttachment,
                command: 'toggleAttachmentPanel',
                iconVariant: 'primary',
                icon: <Attachments data-test-id="toggle-attachments-icon" />
            },
            'raise-hand': {
                key: 'raise-hand',
                title: IvicosStrings.raiseHandActionTitle,
                command: 'toggleRaiseHand',
                iconVariant: handWaveColorChanger(isLocalUserHandRaised, isRaiseHandButtonDisabled),
                icon: (
                    <IC variant="dark" size={21}>
                        <RaiseHand />
                    </IC>
                )
            }
        };
    } else if (smallLaptop) {
        callActions = {
            'device-settings': {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleVirtualBackgroundDialog',
                iconVariant: isButtonDisabled ? 'deactivated' : isVisitorAtLandingPage ? 'primary' : 'dark',
                icon: isVisitorAtLandingPage ? <SettingsVisitor /> : <Settings />
            },
            'toggle-audio': {
                key: 'toggle-audio',
                title: IvicosStrings.toggleAudioActionTitle,
                command: 'toggleAudio',
                icon: micIsMuted ? <MicOff /> : <Mic />
            },
            'toggle-video': {
                key: 'toggle-video',
                title: IvicosStrings.toggleVideoActionTitle,
                command: 'toggleVideo',
                icon: cameraIsMuted ? <VideoOff /> : <Video />
            },
            'toggle-view': {
                key: 'toggle-view',
                title: IvicosStrings.toggleTileViewActionTitle,
                command: 'toggleTileView',
                iconVariant: isButtonDisabled ? 'deactivated' : 'dark',
                icon: tileViewIsEnabled ? <GalleryView /> : <SpeakerView />
            },
            'toggle-stats': {
                key: 'toggle-stats',
                title: IvicosStrings.toggleStatsActionTitle,
                command: 'toggleStats',
                iconVariant: 'primary',
                icon: <SpeakerStatsBars />
            },
            'share-screen': {
                key: 'share-screen',
                title: sharingIsOn ? IvicosStrings.shareScreenActionActiveTitle : IvicosStrings.shareScreenActionTitle,
                command: 'toggleShareScreen',
                data: { enable: !sharingIsOn },
                iconVariant: isButtonDisabled ? 'deactivated' : 'primary',
                // iconVariant: shouldDisableButton ? 'deactivated' : sharingIsOn ? 'red' : 'green',
                icon: sharingIsOn ? <ShareScreenOff /> : <ShareScreen />
            },
            'toggle-chat': {
                key: 'toggle-chat',
                title: 'Open Chat',
                command: 'toggleChat',
                iconVariant: 'primary',
                icon: (
                    <IC variant="dark" size={21}>
                        {chatIconSvg}
                    </IC>
                )
            },
            'toggle-sherloq': {
                key: 'toggle-sherloq',
                title: IvicosStrings.sherloqTitleAskSherloq,
                command: 'toggleSherloq',
                iconVariant: 'primary',
                icon: <SherloqIcon />
            },
            // 'encrypt-room': {
            //     key: 'encrypt-room',
            //     title: encryptionIsEnabled ? IvicosStrings.toggleEncryptionActionActiveTitle : IvicosStrings.toggleEncryptionActionTitle,
            //     command: 'toggleE2ee',
            //     iconVariant: 'dark',
            //     icon: <EncryptedRoom />
            // },

            'set-topic': !(localProfile?.type === 'visitor' || localProfile?.type === 'auto-confirmed-visitor')
                ? {
                      key: 'set-topic',
                      title: IvicosStrings.setATopicActionTitle,
                      onSetTopic: () => {
                          setToggleSetTopicDialog(false);
                          setShowTopic(true);
                      },
                      icon: (
                          <IC size={18} variant="custom">
                              <EditIcon />
                          </IC>
                      )
                  }
                : {
                      key: 'set-topic',
                      title: '',
                      onSetTopic: () => {
                          // console.log('not applicable');
                      },
                      icon: <></>
                  },

            'leave-room': {
                key: 'leave-room',
                title: sharingIsOn ? IvicosStrings.alertMessageWhileScreenShareIsActive : IvicosStrings.leaveRoomActionTitle,
                onCommandSent: () => {
                    !sharingIsOn && routeHistory('/areas/' + currentAreaId);
                    if (localStorage.getItem('topicTitle')) {
                        localStorage.removeItem('topicTitle');
                    }

                    if (localStorage.getItem('roomIdWithTopic')) {
                        localStorage.removeItem('roomIdWithTopic');
                    }

                    setTopic('');
                    setShowTopic(false);
                    setTimeout(() => {
                        if (sessionStorage.getItem('whitelist-roomId')) {
                            sessionStorage.removeItem('whitelist-roomId');
                        }
                    }, 1000);
                },
                iconVariant: sharingIsOn ? 'deactivated' : 'red',
                icon: <Exit data-test-id="leave-room-icon" />
            },
            'toggle-tldraw': {
                key: 'toggle-tldraw',
                title: 'Toggle Tldraw',
                command: 'toggleAddOn-tldraw',
                iconVariant: addOnIsVisible && activeAddOn == 'tldraw' ? 'red' : 'dark',
                icon: <Marker />
            },
            'toggle-etherpad': {
                key: 'toggle-etherpad',
                title: 'Toggle Etherpad',
                command: 'toggleAddOn-etherpad',
                iconVariant: addOnIsVisible && activeAddOn == 'etherpad' ? 'red' : 'dark',
                icon: <SvgEtherpad />
            },
            'toggle-collaboard': {
                key: 'toggle-collaboard',
                title: 'Toggle Collaboard',
                command: 'toggleAddOn-collaboard',
                iconVariant: addOnIsVisible && activeAddOn == 'collaboard' ? 'red' : 'dark',
                icon: <Marker />
            },
            'toggle-attachments': {
                key: 'toggle-attachments',
                title: IvicosStrings.toggleAttachment,
                command: 'toggleAttachmentPanel',
                iconVariant: 'primary',
                icon: <Attachments />
            },
            'raise-hand': {
                key: 'raise-hand',
                title: IvicosStrings.raiseHandActionTitle,
                command: 'toggleRaiseHand',
                iconVariant: handWaveColorChanger(isLocalUserHandRaised, isRaiseHandButtonDisabled),
                icon: (
                    <IC variant="dark" size={21}>
                        <RaiseHand />
                    </IC>
                )
            }
        };
    } else {
        callActions = {
            'device-settings': {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleVirtualBackgroundDialog',
                iconVariant: isButtonDisabled ? 'deactivated' : isVisitorAtLandingPage ? 'primary' : 'dark',
                icon: isVisitorAtLandingPage ? <SettingsVisitor /> : <Settings />
            },
            'toggle-audio': {
                key: 'toggle-audio',
                title: IvicosStrings.toggleAudioActionTitle,
                command: 'toggleAudio',
                icon: micIsMuted ? <MicOff /> : <Mic />
            },
            'toggle-video': {
                key: 'toggle-video',
                title: IvicosStrings.toggleVideoActionTitle,
                command: 'toggleVideo',
                icon: cameraIsMuted ? <VideoOff /> : <Video />
            },
            'toggle-view': {
                key: 'toggle-view',
                title: IvicosStrings.toggleTileViewActionTitle,
                command: 'toggleTileView',
                iconVariant: isButtonDisabled ? 'deactivated' : 'dark',
                icon: tileViewIsEnabled ? <GalleryView /> : <SpeakerView />
            },
            'toggle-stats': {
                key: 'toggle-stats',
                title: IvicosStrings.toggleStatsActionTitle,
                command: 'toggleStats',
                iconVariant: 'primary',
                icon: <SpeakerStatsBars />
            },
            'raise-hand': {
                key: 'raise-hand',
                title: IvicosStrings.raiseHandActionTitle,
                command: 'toggleRaiseHand',
                iconVariant: handWaveColorChanger(isLocalUserHandRaised, isRaiseHandButtonDisabled),
                icon: (
                    <IC variant="dark" size={21}>
                        <RaiseHand />
                    </IC>
                )
            },
            'toggle-chat': {
                key: 'toggle-chat',
                title: 'Open Chat',
                command: 'toggleChat',
                iconVariant: 'primary',
                icon: (
                    <IC variant="dark" size={21}>
                        {chatIconSvg}
                    </IC>
                )
            },
            'toggle-sherloq': {
                key: 'toggle-sherloq',
                title: IvicosStrings.sherloqTitleAskSherloq,
                command: 'toggleSherloq',
                iconVariant: 'primary',
                icon: <SherloqIcon />
            },
            'set-topic': !(localProfile?.type === 'visitor' || localProfile?.type === 'auto-confirmed-visitor')
                ? {
                      key: 'set-topic',
                      title: IvicosStrings.setATopicActionTitle,
                      onSetTopic: () => {
                          setToggleSetTopicDialog(false);
                          setShowTopic(true);
                      },
                      icon: (
                          <Stack style={{ marginRight: 6 }}>
                              {' '}
                              <IC size={18} variant="custom">
                                  <EditIcon />
                              </IC>
                          </Stack>
                      )
                  }
                : {
                      key: 'set-topic',
                      title: '',
                      onSetTopic: () => {
                          // console.log('not applicable');
                      },
                      icon: <></>
                  },

            'leave-room': {
                key: 'leave-room',
                title: sharingIsOn ? IvicosStrings.alertMessageWhileScreenShareIsActive : IvicosStrings.leaveRoomActionTitle,
                onCommandSent: () => {
                    !sharingIsOn && routeHistory('/areas/' + currentAreaId);
                    if (localStorage.getItem('topicTitle')) {
                        localStorage.removeItem('topicTitle');
                    }

                    if (localStorage.getItem('roomIdWithTopic')) {
                        localStorage.removeItem('roomIdWithTopic');
                    }

                    setTopic('');
                    setShowTopic(false);
                    setTimeout(() => {
                        if (sessionStorage.getItem('whitelist-roomId')) {
                            sessionStorage.removeItem('whitelist-roomId');
                        }
                    }, 1000);
                },
                iconVariant: sharingIsOn ? 'deactivated' : 'red',
                icon: <Exit data-test-id="leave-room-icon" />
            }
        };
    }

    return callActions;
};
