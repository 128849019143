// Listening events
const RECONNECT = 'reconnect';
const RECONNECT_ATTEMPT = 'reconnect_attempt';
const RECONNECT_ERROR = 'reconnect_error';
const RECONNECT_FAILED = 'reconnect_failed';
const SERVER_EMIT_USER_JOINED = 'server-emit-user-joined';
const SERVER_EMIT_USER_JOINED_AN_AREA = 'server-emit-user-joined-an-area';
const SERVER_EMIT_USER_LEFT_OR_LOGGED_OUT = 'server-emit-user-left-or-logged-out';
const SERVER_EMIT_ROOM_EVENT = 'server-emit-room-event';
const SERVER_EMIT_MESSAGE_EVENT = 'server-emit-message-event';
const SERVER_EMIT_KICK_VISITOR = 'server-emit-kick-visitor';
const SERVER_EMIT_VISITOR_JOINED = 'server-emit-visitor-joined';
const SERVER_EMIT_USER_AT_VISITOR_ENTRANCE = 'server-emit-user-at-visitor-entrance';
const SERVER_EMIT_TOKEN_TO_CONFIRM_VISITOR = 'server-emit-token-to-confirm-visitor';
const SERVER_EMIT_USER_PROFILE = 'server-emit-user-profile';
const SERVER_EMIT_HOST_USER_AT_VISITOR_ENTRANCE = 'server-emit-host-user-at-visitor-entrance';
const SERVER_EMIT_CROSS_AREA_MESSAGE_USER_LIST = 'server-emit-cross-area-message-user-list';
const SERVER_EMIT_ROOM_SESSION_TOKEN = 'server-emit-room-session-token';
const SERVER_EMIT_NUMBER_OF_USERS_AND_VISITORS_IN_AREAS = 'server-emit-number-of-users-and-visitors-in-areas';

// Emitting events
const CLIENT_EMIT_USER_JOINED = 'client-emit-user-joined';
const CLIENT_EMIT_USER_JOINED_AN_AREA = 'client-emit-user-joined-an-area';
const CLIENT_EMIT_ROOM_EVENT = 'client-emit-room-event';
const CLIENT_EMIT_MESSAGE_EVENT = 'client-emit-message-event';
const CLIENT_EMIT_VISITOR_JOINED = 'client-emit-visitor-joined';
const CLIENT_EMIT_USER_AT_VISITOR_ENTRANCE = 'client-emit-user-at-visitor-entrance';
const CLIENT_EMIT_TOKEN_TO_CONFIRM_VISITOR = 'client-emit-token-to-confirm-visitor';
const CLIENT_EMIT_USER_PROFILE = 'client-emit-user-profile';

export {
    RECONNECT,
    RECONNECT_ATTEMPT,
    RECONNECT_ERROR,
    RECONNECT_FAILED,
    SERVER_EMIT_USER_JOINED,
    SERVER_EMIT_USER_JOINED_AN_AREA,
    SERVER_EMIT_USER_LEFT_OR_LOGGED_OUT,
    SERVER_EMIT_ROOM_EVENT,
    SERVER_EMIT_MESSAGE_EVENT,
    SERVER_EMIT_KICK_VISITOR,
    SERVER_EMIT_VISITOR_JOINED,
    SERVER_EMIT_USER_AT_VISITOR_ENTRANCE,
    SERVER_EMIT_TOKEN_TO_CONFIRM_VISITOR,
    SERVER_EMIT_USER_PROFILE,
    SERVER_EMIT_HOST_USER_AT_VISITOR_ENTRANCE,
    SERVER_EMIT_CROSS_AREA_MESSAGE_USER_LIST,
    SERVER_EMIT_ROOM_SESSION_TOKEN,
    CLIENT_EMIT_ROOM_EVENT,
    CLIENT_EMIT_USER_JOINED,
    CLIENT_EMIT_USER_JOINED_AN_AREA,
    CLIENT_EMIT_MESSAGE_EVENT,
    CLIENT_EMIT_VISITOR_JOINED,
    CLIENT_EMIT_USER_AT_VISITOR_ENTRANCE,
    CLIENT_EMIT_TOKEN_TO_CONFIRM_VISITOR,
    CLIENT_EMIT_USER_PROFILE,
    SERVER_EMIT_NUMBER_OF_USERS_AND_VISITORS_IN_AREAS
};
