import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import IvicosStrings from 'kits/language/stringKit';

export interface IAreaListsDetails {
    key: string;
    text: string;
}
export interface IRoomListsDetails {
    key: string;
    text: string;
}

export const formFieldsForWaitingRoom: (changes: any, areaNamesList: any, fetchedRooms: any) => IDynamicFormElementProps[] = (
    changes,
    areaNamesList,
    fetchedRooms
) => {
    return [
        {
            key: 'area',
            label: IvicosStrings.areaTitle,
            value: {
                selectedKey: (changes.area && changes.area.selectedKey) || 'area area',
                options: areaNamesList
            },
            type: 'dropdown'
        },
        changes.area && changes.area.selectedKey
            ? {
                  key: 'room',
                  label: IvicosStrings.commonRoomsSectionTitle,
                  value: changes.room && changes.room,
                  type: 'room-list',
                  items: fetchedRooms,
                  height: '250px',
                  overflow: 'scroll',
                  disabled: changes.area && changes.area.selectedKey !== undefined ? false : true
              }
            : {
                  key: 'room',
                  type: 'section-heading',
                  label: ''
              }
    ];
};
