import { Stack } from '@fluentui/react';
import { useOrganizationBranding } from 'features/Header/hooks';
import OutsideCallController from 'features/Visitors/Entrance/OutsideCallController/OutsideCallController';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import React from 'react';
import { Navigate } from 'react-router';
import { ILandingPageState } from './LandingPage.state';
import LandingPageSideCard from './LandingPageSideCard';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const LandingPageView: React.FC<ILandingPageState> = (props) => {
    const { videntityClaims, customUrl, invitationId, eventName, orgId, goInClicked } = props;

    const redirectVisitor = invitationId ? invitationId : customUrl;

    if (!localStorage.getItem('ivAccessToken')) return <Navigate to={'/i/' + redirectVisitor} />;

    const [branding] = useOrganizationBranding(orgId);
    const { smallDevice, mediumDevice } = useWindowWidth();
    const backgroundImage = branding && branding.visitor_entrance_background_image;
    const desktopLayout = { marginTop: 16, width: 340, marginRight: 16, marginLeft: 30 };
    const mobileLayout = { marginTop: 16, width: '45vh' };

    return (
        <>
            <OutsideWrapper backgroundImage={backgroundImage}>
                {/* Jitsi call */}
                {videntityClaims && (
                    <Stack verticalAlign="center">
                        <OutsideCallController displayName={videntityClaims.displayName} />
                    </Stack>
                )}

                {/* Side card */}
                <Stack verticalAlign="center" style={smallDevice || mediumDevice ? mobileLayout : desktopLayout}>
                    <LandingPageSideCard eventName={eventName} branding={branding} videntityClaims={videntityClaims} goInClicked={goInClicked} />
                </Stack>
            </OutsideWrapper>
        </>
    );
};

export default LandingPageView;
