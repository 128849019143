import { selector, selectorFamily } from 'recoil';
import { sProfile } from 'shared-state/identity/selectors';
import { IProfile } from 'shared-state/identity/types';
import { aDirectMessageUserList, aUserList, aUsersAndVisitorsNumber, aVisitorList } from './atoms';

export const sUsers = selector<IProfile[]>({
    key: 'sUsers',
    get: ({ get }) => {
        const allUsers = get(aUserList);
        const myProfile = get(sProfile);

        const localUsers = myProfile ? [myProfile] : [];

        return [...allUsers, ...localUsers];
    }
});

export const sUsersInRoom = selectorFamily<IProfile[], string>({
    key: 'sUsersInRoom',
    get:
        (roomId: string) =>
        ({ get }) => {
            const allUsersIncludingLocalUsers = get(sUsers);
            return allUsersIncludingLocalUsers.filter((u) => u.room == roomId);
        }
});

export const sUsersWithIDs = selectorFamily<IProfile[], string[]>({
    key: 'sUsersInRoom',
    get:
        (userIds: string[]) =>
        ({ get }) => {
            const allUsersIncludingLocalUsers = get(sUsers);
            return allUsersIncludingLocalUsers.filter((u) => userIds.includes(u.uid));
        }
});

export const sCrossAreaUsersWithIDs = selectorFamily<IProfile[], string[]>({
    key: 'sCrossAreaUsers',
    get:
        (userIds: string[]) =>
        ({ get }) => {
            const allUsersInCampus = get(aDirectMessageUserList);
            return allUsersInCampus.filter((u) => userIds.includes(u.uid));
        }
});

export const sUser = selectorFamily<IProfile | undefined, string>({
    key: 'sUser',
    get:
        (userId: string) =>
        ({ get }) => {
            const allUsersIncludingLocalUsers = get(sUsers);
            return allUsersIncludingLocalUsers.find((u) => u.uid == userId);
        }
});

export const sCrossAreaUser = selectorFamily<IProfile | undefined, string>({
    key: 'sCrossAreaUser',
    get:
        (userId: string) =>
        ({ get }) => {
            const allUsersInCampus = get(aDirectMessageUserList);
            return allUsersInCampus.find((u) => u.uid == userId);
        }
});

export const sVisitorEntranceUser = selectorFamily<IProfile | undefined, string>({
    key: 'sVisitorEntranceUser',
    get:
        (userId: string) =>
        ({ get }) => {
            const allVisitorsAtVisitorEntrance = get(aVisitorList);
            const hostsListAtVE = JSON.parse(sessionStorage.getItem('hostsListAtVE') || 'null');

            const visitorMatch = allVisitorsAtVisitorEntrance.find((u) => u.uid === userId);
            const hostMatch = hostsListAtVE?.find((h) => h.uid === userId);

            return visitorMatch || hostMatch;
        }
});

export const sUserFromSpeakerStats = selectorFamily<IProfile | undefined, string>({
    key: 'sUserFromSpeakerStats',
    get:
        (userName: string) =>
        ({ get }) => {
            const allUsersIncludingLocalUsers = get(sUsers);
            return allUsersIncludingLocalUsers.find((u) => u.displayName == userName);
        }
});

export const sUserAndVisitorNumberInArea = selectorFamily<{ users: number; visitors: number }, string>({
    key: 'sUserAndVisitorNumberInArea',
    get:
        (areaId: string) =>
        ({ get }) => {
            if (!areaId) {
                return { users: 0, visitors: 0 };
            }
            const usersAndVisitorsNumber = get(aUsersAndVisitorsNumber);

            // Access the specific area's data using the areaId as key
            const areaData = usersAndVisitorsNumber[areaId] || { users: 0, visitors: 0 };
            return areaData;
        }
});
