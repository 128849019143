/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { AbstractService } from '../shared/abstract-service';
import type * as Dto from './responses.dto';
import { SherloqResponse } from '../sherloq/responses.dto';

export class IdentityProviderService extends AbstractService {
    private readonly authPath: string;
    private readonly mePath: string;
    private readonly invPath: string;
    private readonly orgPath: string;
    private readonly checksPath: string;
    private readonly invitedPath: string;
    private readonly membersPath: string;
    private readonly magicLinkPath: string;
    private readonly createNewUserAccountPath: string;
    private readonly sherloqPath: string;

    constructor(baseUrl: string, headerRetrievalFunction?: () => { [index: string]: string }) {
        super(baseUrl, headerRetrievalFunction);
        this.authPath = '/v1/auth';
        this.mePath = '/v1/me';
        this.invPath = '/v1/invitations';
        this.orgPath = '/v1/organization';
        this.checksPath = '/v1/checks';
        this.invitedPath = '/invited';
        this.membersPath = '/members';
        this.magicLinkPath = '/v1/magic_link';
        this.createNewUserAccountPath = '/v1/create_new_user_account';
        this.sherloqPath = '/v1/sherloq';
        // this.sherloqPath = '/v1/sherloq/predict';
    }

    // Magic link
    public async magicLink(
        email: string,
        preferred_language: string,
        useAlternativeEmailService?: boolean,
        requestCharactersHeader?: string,
        requestCharactersBody?: string
    ) {
        const response = await axios.post(
            `${this.baseUrl}${this.magicLinkPath}`,
            {
                email: email,
                preferred_language: preferred_language,
                use_alternate_email_service: useAlternativeEmailService,
                request_character: requestCharactersBody
            },
            {
                headers: {
                    'X-Request-Character': requestCharactersHeader
                }
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data;
    }

    // Request verification characters
    public async requestVerificationCharacters() {
        const response = await axios.get(`${this.baseUrl}/v1/request_characters/get-characters`);

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data;
    }

    // Magic link
    public async createNewUserAccount(email: string, preferred_language: string) {
        const response = await axios.post(`${this.baseUrl}${this.createNewUserAccountPath}`, {
            email: email,
            preferred_language: preferred_language
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data;
    }

    // Authentication Controller
    public async authUser(magicLinkToken: string) {
        const response = await axios.post(`${this.baseUrl}${this.authPath}`, {
            magic_link_token: magicLinkToken
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);

        return response.data.language;
    }

    public async authUserToOrg(orgId: string) {
        const response = await axios.get(`${this.baseUrl}${this.authPath}/${orgId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);
    }

    // MS Authentication Controller
    public async authUserMS(microsoftBearerToken: string) {
        const response = await axios.post(`${this.baseUrl}${this.authPath}/ms`, {
            microsoftBearerToken: microsoftBearerToken
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);
    }

    // Show Controller
    public async getUser() {
        const response = await axios.get(`${this.baseUrl}${this.mePath}/info`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.UserDto;
    }

    public async updateUser(
        name?: string,
        profile_pic?: string,
        position?: string,
        preferred_language?: string,
        camera_disabled?: boolean,
        mic_disabled?: boolean
    ) {
        const device_settings_to_request =
            camera_disabled != undefined && mic_disabled != undefined ? { camera_disabled: camera_disabled, mic_disabled: mic_disabled } : undefined;
        const response = await axios.patch(
            `${this.baseUrl}${this.mePath}/update`,
            {
                fields: {
                    name: name,
                    profile_pic: profile_pic,
                    position: position,
                    preferred_language: preferred_language,
                    device_settings: device_settings_to_request
                }
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.UserDto;
    }

    public async videoDisplayedToUser() {
        const response = await axios.patch(
            `${this.baseUrl}${this.mePath}/update`,
            {
                fields: {
                    videoDisplayed: true
                }
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.UserDto;
    }

    public async showUserJoinedOrgs() {
        const response = await axios.get(`${this.baseUrl}${this.mePath}/orgs`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.UserJoinedOrgsDto;
    }

    public async userJoinOrg(orgId: string, role: 'user' | 'manager') {
        const response = await axios.patch(
            `${this.baseUrl}${this.mePath}/join-organization`,
            {
                orgId: orgId,
                role: role
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);
    }

    public async userQuitOrg(orgId: string) {
        const response = await axios.patch(
            `${this.baseUrl}${this.mePath}/quit-organization`,
            {
                orgId: orgId
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.UserJoinedOrgsDto;
    }

    public async showUserPendingInvitations() {
        const response = await axios.get(`${this.baseUrl}${this.mePath}/pending-invitations`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.UserPendingInvitationsDto;
    }

    // Invitations Controller
    public async createInvitation(
        campusId: string,
        hosts: string[],
        event_name: string,
        type: string,
        repeat: 'no repeat' | 'weekly' | 'daily',
        from: string,
        to: string,
        areaId: string,
        roomId: string,
        welcome_message: string
    ) {
        const response = await axios.post(
            `${this.baseUrl}${this.invPath}`,
            {
                campusId: campusId,
                hosts: hosts,
                event_name: event_name,
                type: type,
                repeat: repeat,
                open_from: from,
                open_to: to,
                default_destination: {
                    areaId: areaId,
                    roomId: roomId
                },
                welcome_message: welcome_message
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 201) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.InvitationDto;
    }

    public async showUserInvitationsList() {
        const response = await axios.get(`${this.baseUrl}${this.invPath}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ListInvitationsDto;
    }

    public async showInvitation(custom_url?: string, invitationId?: string) {
        const response = await axios.post(`${this.baseUrl}${this.invPath}/show`, {
            fields: {
                custom_url,
                invitationId
            }
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.InvitationDto;
    }

    public async updateCustomizedVideoUrl(invitationId: string, customized_video: string) {
        const response = await axios.patch(
            `${this.baseUrl}${this.invPath}/customized-video-url/${invitationId}`,
            {
                customized_video: customized_video
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }
        return response.data as Dto.InvitationDto;
    }

    public async updateCustomUrl(invitationId: string, custom_path: string) {
        const response = await axios.patch(
            `${this.baseUrl}${this.invPath}/custom-url/${invitationId}`,
            {
                custom_path: custom_path
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }
        return response.data as Dto.InvitationDto;
    }

    public async deleteInvitation(invitationId: string) {
        const response = await axios.delete(`${this.baseUrl}${this.invPath}/${invitationId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 204) {
            throw new Error(response.statusText);
        }

        return response.statusText;
    }

    // Visitor Authentication
    public async authVisitor(name: string, invitationId: string) {
        const response = await axios.post(`${this.baseUrl}${this.authPath}/invitation/${invitationId}`, {
            name: name
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);
    }

    public async confirmVisitor(areaId: string, campusId: string, roomId: string, invitationId: string, visitorId: string): Promise<string | undefined> {
        const response = await axios.post(
            `${this.baseUrl}${this.authPath}/invitation/${invitationId}/confirm/${visitorId}`,
            {
                roomId: roomId,
                areaId: areaId,
                campusId: campusId
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        if (typeof response.data.token == 'string') return response.data.token;
    }

    public async autoConfirmVisitor(name: string, invitationId: string) {
        const response = await axios.post(`${this.baseUrl}${this.authPath}/invitation/${invitationId}/auto-confirm`, {
            name: name
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);
    }

    public useConfirmationToken(confirmationToken: string): boolean {
        const currentToken = localStorage.getItem('ivAccessToken');
        if (!currentToken) return false;
        const accessToken = currentToken?.split(':')[0];

        const nextToken = accessToken + ':' + confirmationToken;
        localStorage.setItem('ivAccessToken', nextToken);
        return true;
    }

    // Organization Controller
    public async createOrg(
        orgName: string,
        orgSize: '1-25' | '25-50' | '50-100' | '100-500' | 'more than 500',
        locationsCount: '1-5' | '5-10' | '10-20' | 'more than 20'
    ) {
        const response = await axios.post(
            `${this.baseUrl}${this.orgPath}`,
            {
                orgName: orgName,
                orgSize: orgSize,
                locationsCount: locationsCount
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 201) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.OrganizationDto;
    }

    public async updateOrg(
        orgName?: string,
        orgLogo?: string,
        orgColors?: string[],
        visitor_entrance_background_image?: string,
        visitor_entrance_video_url?: string,
        visitor_farewell_message?: string,
        orgSize?: '1-25' | '25-50' | '50-100' | '100-500' | 'more than 500',
        locationsCount?: '1-5' | '5-10' | '10-20' | 'more than 20'
    ) {
        const response = await axios.patch(
            `${this.baseUrl}${this.orgPath}`,
            {
                fields: {
                    orgName: orgName,
                    orgLogo: orgLogo,
                    orgColors: orgColors,
                    visitor_entrance_background_image: visitor_entrance_background_image,
                    visitor_entrance_video_url: visitor_entrance_video_url,
                    visitor_farewell_message: visitor_farewell_message,
                    orgSize: orgSize,
                    locationsCount: locationsCount
                }
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.OrganizationDto;
    }

    public async showOrg() {
        const response = await axios.get(`${this.baseUrl}${this.orgPath}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.OrganizationDto;
    }

    public async getOrgBranding(orgId: string) {
        const response = await axios.get(`${this.baseUrl}${this.orgPath}/${orgId}/branding`);

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.OrgBrandingDto;
    }

    public async showOrgInvited() {
        const response = await axios.get(`${this.baseUrl}${this.orgPath}${this.invitedPath}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ShowOrgInvitedDto;
    }

    public async newInvited(email: string, role: 'user' | 'manager', language: string, type?: 'anyone_bulk') {
        const response = await axios.post(
            `${this.baseUrl}${this.orgPath}${this.invitedPath}`,
            {
                email: email,
                role: role,
                language: language,
                type: type ? type : 'regular'
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ShowOrgInvitedDto;
    }

    public async emailHasSponsor(email: string) {
        const response = await axios.post(
            `${this.baseUrl}${this.orgPath}${this.invitedPath}/sponsor`,
            {
                email: email
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.EmailHasSponsor;
    }

    public async deleteInvited(email: string) {
        const response = await axios.patch(
            `${this.baseUrl}${this.orgPath}${this.invitedPath}`,
            {
                email: email
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ShowOrgInvitedDto;
    }

    public async declineInvitationToJoinOrg(email: string, invOrgId: string) {
        const response = await axios.patch(
            `${this.baseUrl}${this.orgPath}${this.invitedPath}/decline`,
            {
                email: email,
                invOrgId: invOrgId
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ShowOrgInvitedDto;
    }

    public async showMembers() {
        const response = await axios.get(`${this.baseUrl}${this.orgPath}${this.membersPath}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ShowMembersListDto;
    }

    public async changeMemberRole(newRole: 'user' | 'manager', userId: string) {
        const response = await axios.patch(
            `${this.baseUrl}${this.orgPath}${this.membersPath}/${userId}`,
            {
                newRole: newRole
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ChangeMemberRoleMessage;
    }

    public async showMemberProfile(userId: string) {
        const response = await axios.get(`${this.baseUrl}${this.orgPath}${this.membersPath}/${userId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ShowMemberProfileDto;
    }

    public async removeMember(userId: string) {
        const response = await axios.delete(`${this.baseUrl}${this.orgPath}${this.membersPath}/${userId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 204) {
            throw new Error(response.statusText);
        }
    }

    public async showBilling() {
        const response = await axios.get(`${this.baseUrl}${this.orgPath}/billing`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data;
    }

    public async archiveOrg() {
        const response = await axios.delete(`${this.baseUrl}${this.orgPath}`, {
            headers: this.getHeaders()
        });

        if (response.status != 204) {
            throw new Error(response.statusText);
        }
    }

    // Checks Controller
    public async checkSetupCompleted() {
        const response = await axios.get(`${this.baseUrl}${this.checksPath}/setup_completed`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.CheckSetupCompletedDto;
    }

    // Sherloq Controller
    public async configureSherloqSettings(
        customer: string,
        model: string,
        clientId: string,
        clientSecret: string,
        isConnectionBeingTested = false,
        examplePrompts: string[] = [
            'Wer ist der Bürgermeister von Freiburg?',
            'Wer bezahlt die Kindergartenplätze in Freiburg?',
            'Wie groß ist der schönste Springbrunnen der Stadt?'
        ]
    ) {
        const response = await axios.post(
            `${this.baseUrl}${this.sherloqPath}/configure`,
            {
                fields: {
                    customer: customer,
                    model: model,
                    clientId: clientId,
                    clientSecret: clientSecret,
                    isConnectionBeingTested: isConnectionBeingTested,
                    examplePrompts: examplePrompts
                }
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data;
    }

    public async makePredictRequest(prompt: string): Promise<string> {
        const queryParams = new URLSearchParams({ query: prompt });

        const requestUrl = `${this.baseUrl}${this.sherloqPath}/predict?${queryParams.toString()}`;

        const response = await axios.get<SherloqResponse>(requestUrl, { headers: this.getHeaders() });

        return response.data.data.result.answer;
    }

    public async getSherloqSettings() {
        const response = await axios.get(`${this.baseUrl}${this.sherloqPath}/settings`, { headers: this.getHeaders() });
        return response.data;
    }

    public async deactivateSherloq() {
        const response = await axios.post(
            `${this.baseUrl}${this.sherloqPath}/deactivate`,
            {}, // empty body
            { headers: this.getHeaders() }
        );
        return response.data;
    }

    // SAML Authentication Controller
    public async authUserSAML(samlToken: string, configId: string) {
        const response = await axios.post(`${this.baseUrl}${this.authPath}/saml/authenticate`, {
            samlToken: samlToken,
            samlConfigId: configId
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        localStorage.setItem('ivAccessToken', response.data.token);
    }
}
