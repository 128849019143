import React from 'react';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
// import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useLandingPageState } from './LandingPage.state';
// import LandingPageResponsiveView from './LandingPageResponsive.view';
import LandingPageView from './LandingPage.view';

const LandingPage = (): any => {
    const state = useLandingPageState();
    // const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <>
            {state.isLoadingDialogVisible === 'true' && <LoadingDialog loadingStrings={['Loading landing page...']} view="full" typewriter={false} />}
            <LandingPageView
                {...{
                    ...state
                }}
            />
        </>
    );
};

export default LandingPage;
