import { DefaultEffects, Stack, getTheme } from '@fluentui/react';
import React from 'react';
import { ICallSurfaceProps } from './CallSurface';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useIsVisitorAtLandingPage } from 'shared-state/location/hook';

const theme = getTheme();
const CallSurfaceView: React.FC<ICallSurfaceProps> = ({ borderColor, children, onMouseMove, isHidden }) => {
    const { smallDevice, mediumDevice } = useWindowWidth();
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();
    const visitorAreaPath = window.location.pathname.includes('/w');
    const padding = (smallDevice || mediumDevice) && (isVisitorAtLandingPage || visitorAreaPath) ? 0 : 16;
    const marginTop = smallDevice || mediumDevice ? 32 : 0;
    const layout = (smallDevice || mediumDevice) && (isVisitorAtLandingPage || visitorAreaPath) ? '44vh' : '100%';
    return (
        <Stack
            style={{
                backgroundColor: 'transparent',
                display: isHidden ? 'none' : undefined,
                paddingRight: padding,
                paddingLeft: padding,
                paddingTop: padding,
                paddingBottom: borderColor ? 16 : undefined,
                height: layout,
                width: layout,
                marginTop: marginTop
            }}
            onMouseMove={(e) => onMouseMove && onMouseMove(e)}
        >
            <Stack
                verticalAlign="stretch"
                grow
                style={{
                    overflow: 'hidden',
                    position: 'relative',
                    minHeight: '100%',
                    height: '100%',
                    borderRadius: '4px 4px 0px 0px',
                    border: borderColor && `4px ${borderColor} solid`,
                    boxShadow: DefaultEffects.elevation8,
                    backgroundColor: theme.palette.neutralDark
                }}
            >
                {children}
            </Stack>
        </Stack>
    );
};

export default CallSurfaceView;
