// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { AreaDto, CampusDto, RoomDto } from 'services/client-api-wrapper/campus_api/responses.dto';

export interface IResource {
    id: string;
    type: string;
    attributes: any;
    relationships: any;
}

export interface IResourcePointer {
    id: string;
    type: string;
}
export interface IResourceResponse {
    data?: IResource;
    included?: Array<IResource>;
    meta?: any;
    errors?: Array<any>;
}

export interface IAreaResource extends IResource {
    attributes: {
        variant: 'DEFAULT' | 'MSTEAM';
        displayName: string;
    };
    relationships: {
        team?: string;
        campus: string;
        rooms?: Array<IResourcePointer>;
        invitations?: Array<IResourcePointer>;
    };
}

export interface IRoomResource extends IResource {
    attributes: {
        variant: 'DEFAULT';
        displayName: string;
        private: boolean;
        symbol: string;
        openForVisitors?: boolean;
        audioOnly?: boolean;
        backgroundImageURL?: string;
        dimmed?: boolean;
        whitelist?: Array<string>;
        creatorId?: string;
    };
    relationships: {
        area: string;
        owner: string;
    };
}

export interface IInvitationResource extends IResource {
    attributes: {
        ref: string;
        message: string;
        createdAt: Date;
    };
    relationships: {
        inivitingUser: string;
        area: string;
    };
}
export interface IChangelogEntryResource extends IResource {
    attributes: {
        title: string;
        timestamp: string;
        body: string;
        featuredImage?: string;
    };
}

export const campusDTOtoResource = (campusDTO: CampusDto): IResource => {
    return {
        id: campusDTO.id,
        type: 'iv_campus',
        attributes: {
            displayName: campusDTO.domain
        },
        relationships: []
    };
};

export const areaDTOtoResource = (areaDTO: AreaDto): IResource => {
    return {
        id: areaDTO.id,
        type: 'iv_campus_area',
        attributes: {
            variant: 'DEFAULT',
            displayName: areaDTO.name,
            campuses: areaDTO.campusIds,
            idpGroupIdRestriction: areaDTO.idpGroupIdRestriction,
            usersAndVisitorsCount: areaDTO.count
        },
        relationships: []
    };
};

export const roomDTOtoResource = (roomDTO: RoomDto): IRoomResource => {
    return {
        id: roomDTO.id,
        type: 'iv_campus_room',
        attributes: {
            displayName: roomDTO.name,
            symbol: roomDTO.iconKey,
            openForVisitors: roomDTO.isOpenForVisitors,
            audioOnly: roomDTO.isAudioOnly,
            private: roomDTO.isPrivate,
            backgroundImageURL: roomDTO.backgroundImageURL || '',
            variant: 'DEFAULT',
            whitelist: roomDTO.whitelist,
            creatorId: roomDTO.creatorId
        },
        relationships: {
            owner: '*',
            area: roomDTO.areaIds[0]
        }
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const campusAndAreasDTOtoResourceResponse = (campusDTO: CampusDto, areasDTOs?: AreaDto[]) => {
    return {
        data: campusDTOtoResource(campusDTO),
        included: areasDTOs ? areasDTOs.map((area) => areaDTOtoResource(area)) : []
    };
};
