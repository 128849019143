/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultButton, Depths, PrimaryButton, Stack, Text } from '@fluentui/react';
import { OrgBrandingDto } from '../../services/client-api-wrapper/identity_provider/responses.dto';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import React, { useContext, useEffect, useState } from 'react';
import IvicosStrings from 'kits/language/stringKit';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import AnimatedDoorbellIcon from './AnimatedDoorBell';
import { Memo } from 'kits/IconKit2';
import WaitingAreaMemoComposer from 'routes/HostPerspectiveWaitingArea/RoomSelectionDialog/WaitingAreaMemoComposer/WaitingAreaMemoComposer';
import { useIsWaitingAreaMemoDialogOpen } from 'shared-state/display-options/hooks';
import { useEvent } from 'kits/eventKit';
import SocketContext from 'services/socket-connection/context/SocketContext';
import { useLocalProfile } from 'shared-state/identity/hooks';
import IvicosLogo from 'media/logos/iv_campus_logo_white.svg';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { WaitingAreaTimerElement } from './WaitingAreaTimerElement';

export interface IWaitingAreaSideCardProps {
    eventName: string;
    branding: OrgBrandingDto | undefined;
    visitorName: string;
    timerProps: {
        isPlaying: boolean;
        size: number;
        strokeWidth: number;
    };
    hourSeconds: number;
    isTimerActive: boolean;
    setIsTimerActive: (isActive: boolean) => void;
    welcomeMessage: string;
    notifyHost: () => void;
    hideWhenHostJoin: boolean;
    isButtonHovered: boolean;
    setIsButtonHovered: React.Dispatch<React.SetStateAction<boolean>>;
    isSendMemoBtnVisible: boolean;
}

const WaitingAreaSideCard: React.FunctionComponent<IWaitingAreaSideCardProps> = ({
    eventName,
    branding,
    visitorName,
    timerProps,
    hourSeconds,
    isTimerActive,
    setIsTimerActive,
    welcomeMessage,
    notifyHost,
    hideWhenHostJoin,
    isButtonHovered,
    setIsButtonHovered,
    isSendMemoBtnVisible
}) => {
    const {
        SocketState: { socket }
    } = useContext(SocketContext);
    const currentUser = useLocalProfile();
    const customLogo = branding && branding.orgLogo;
    const [isWaitingAreaMemoDialogOpen, setIsWaitingAreaMemoDialogOpen] = useIsWaitingAreaMemoDialogOpen();
    const [logoDimensions, setLogoDimensions] = useState({ width: 150, height: 1 });
    const { smallDevice, mediumDevice } = useWindowWidth();

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    useEffect(() => {
        let isSubscribed = true;

        if (isSendMemoBtnVisible && currentUser && socket) {
            socket.emit('client-emit-fetch-hosts-list-at-visitor-entrance', { visitorInfo: currentUser });
        }

        return () => {
            isSubscribed = false;
        };
    }, [currentUser, socket, isSendMemoBtnVisible]);

    useEffect(() => {
        const img = new Image();
        let isSubscribed = true;

        img.onload = () => {
            if (isSubscribed) {
                const aspectRatio = img.naturalWidth / img.naturalHeight;
                const calculatedWidth = aspectRatio > 1.2 ? 150 : 50;
                setLogoDimensions({ width: calculatedWidth, height: img.naturalHeight });
            }
        };

        img.src = customLogo || IvicosLogo;

        return () => {
            isSubscribed = false;
        };
    }, [customLogo]);

    return (
        <Stack style={{ boxShadow: Depths.depth64 }}>
            <NotificationsLayer />
            <Stack style={{ background: backgroundColor }}>
                {/* Logo */}
                <Stack
                    aria-label="visitor-waiting-area-logo"
                    key="brand"
                    style={{
                        height: '50px',
                        width: logoDimensions.width,
                        backgroundImage: `url(${customLogo || IvicosLogo})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left center',
                        margin: '8px 20px'
                    }}
                />
                {/* Event name */}
                <Stack horizontal verticalAlign={'center'} style={{ padding: '8px 22px' }}>
                    <Text
                        style={{
                            lineHeight: '30px',
                            color: getTextColorForBackground(backgroundColor),
                            width: '100%',
                            wordBreak: 'break-word'
                        }}
                        variant="xLargePlus"
                        aria-label="visitor-waiting-area-event-name"
                    >
                        {eventName}
                    </Text>
                </Stack>

                {isSendMemoBtnVisible && (
                    <Stack horizontal style={{ padding: '16px 22px' }}>
                        <DefaultButton text={IvicosStrings.sendHostMemo} onRenderIcon={() => <Memo />} onClick={() => setIsWaitingAreaMemoDialogOpen(true)} />
                        {isWaitingAreaMemoDialogOpen && (
                            <WaitingAreaMemoComposer
                                sentBy="visitor"
                                isOpen={isWaitingAreaMemoDialogOpen}
                                onClose={() => setIsWaitingAreaMemoDialogOpen(false)}
                            />
                        )}
                    </Stack>
                )}
            </Stack>
            {!hideWhenHostJoin && (
                <Stack style={{ background: '#fff' }}>
                    <Stack
                        tokens={{ childrenGap: 10 }}
                        style={{
                            width: '100%',
                            padding: '10px 20px 0 20px'
                            // height: 350
                        }}
                    >
                        {/* Greet visitor */}
                        <Stack style={{ paddingTop: 8, paddingBottom: 4 }}>
                            <Text variant="mediumPlus" aria-label="visitor-waiting-area-greet">
                                <strong>
                                    {IvicosStrings.waitingAreaHelloVisitor} {visitorName}!
                                </strong>
                            </Text>
                        </Stack>

                        {/* Welcome message */}
                        {welcomeMessage && welcomeMessage !== 'wElCoMe_MeSsAgE' && (
                            <Stack style={{ padding: '4px 0' }}>
                                <Text aria-label="visitor-waiting-area-welcome-message">{welcomeMessage}</Text>
                            </Stack>
                        )}
                        <Stack style={{ paddingTop: 4, paddingBottom: 8 }}>
                            {isTimerActive ? (
                                <WaitingAreaTimerElement
                                    timerProps={timerProps}
                                    hourSeconds={hourSeconds}
                                    setIsTimerActive={setIsTimerActive}
                                    strings={{
                                        waitingAreaWaitBriefly: IvicosStrings.waitingAreaWaitBriefly,
                                        waitingAreaHostInformed: IvicosStrings.waitingAreaHostInformed
                                    }}
                                />
                            ) : (
                                <Stack verticalAlign="space-around" horizontalAlign="space-around" tokens={{ childrenGap: 16 }}>
                                    {/* No response */}
                                    <Stack>
                                        <Text aria-label="visitor-waiting-area-no-response">
                                            <strong>{IvicosStrings.waitingAreaNoOneGreet}</strong> {IvicosStrings.waitingAreaClickAgain}
                                        </Text>
                                    </Stack>

                                    {/* Notify Host */}
                                    <Stack horizontal horizontalAlign="center">
                                        <PrimaryButton
                                            text={IvicosStrings.waitingAreaNotifyHost}
                                            onClick={() => {
                                                notifyHost();
                                            }}
                                            onMouseEnter={() => setIsButtonHovered(true)}
                                            onMouseLeave={() => setIsButtonHovered(false)}
                                            onRenderIcon={() => <AnimatedDoorbellIcon isButtonHovered={isButtonHovered} />}
                                            aria-label="visitor-waiting-area-notify-host"
                                        />
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
export default WaitingAreaSideCard;
