/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@fluentui/react';
import { useOrganizationBranding } from 'features/Header/hooks';
import OutsideCallController from 'features/Visitors/Entrance/OutsideCallController/OutsideCallController';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import { campusDTOtoResource } from 'kits/apiKit3/legacy';
import React from 'react';
import ReactPlayer from 'react-player';
import { Navigate } from 'react-router';
import SocketProvider from 'services/socket-connection/context/SocketProvider';
import SocketVisitorEntrance from 'services/socket-connection/socket-visitor-entrance';
import { IWaitingAreaState } from './WaitingArea.state';
import WaitingAreaSideCard from './WaitingAreaSideCard';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';

const WaitingAreaResponsiveView: React.FC<IWaitingAreaState> = (props) => {
    const {
        headerActions,
        videntityClaims,
        campus,
        customUrl,
        invitationId,
        isTimerActive,
        setIsTimerActive,
        hourSeconds,
        timerProps,
        notifyHost,
        visitorName,
        eventName,
        customizedVideo,
        shouldNotifyHosts,
        hideWhenHostJoin,
        orgId,
        setIsButtonHovered,
        isButtonHovered,
        tokenPayload,
        welcomeMessage,
        isYoutubeVideoVisible,
        isVisitorWithHost,
        isSendMemoBtnVisible
    } = props;

    const redirectVisitor = invitationId ? invitationId : customUrl;

    if (!localStorage.getItem('ivAccessToken')) return <Navigate to={'/i/' + redirectVisitor} />;
    if (!videntityClaims) return;

    const [branding] = useOrganizationBranding(orgId);
    const showYoutubeVideo = sessionStorage.getItem('isYoutubeVideoVisible') === 'true';
    const backgroundImage = branding?.visitor_entrance_background_image;

    return (
        <>
            <OutsideWrapper campus={campus && campusDTOtoResource(campus)} headerActions={headerActions} backgroundImage={backgroundImage}>
                <SocketProvider>
                    {(shouldNotifyHosts || isVisitorWithHost) && (
                        <SocketVisitorEntrance tokenPayload={tokenPayload} eventName={eventName} isVisitorWithHost={isVisitorWithHost} />
                    )}
                    {isYoutubeVideoVisible && showYoutubeVideo ? (
                        <ReactPlayer url={customizedVideo} controls={true} width={'100%'} />
                    ) : (
                        <Stack verticalAlign="center">
                            <OutsideCallController displayName={videntityClaims.displayName} />
                        </Stack>
                    )}

                    <Stack verticalAlign="center" style={{ marginTop: 16, width: 340, marginRight: 16, marginLeft: 30 }}>
                        <WaitingAreaSideCard
                            eventName={eventName}
                            branding={branding}
                            visitorName={visitorName}
                            timerProps={timerProps}
                            hourSeconds={hourSeconds}
                            isTimerActive={isTimerActive}
                            setIsTimerActive={setIsTimerActive}
                            welcomeMessage={welcomeMessage}
                            notifyHost={notifyHost}
                            hideWhenHostJoin={hideWhenHostJoin}
                            isButtonHovered={isButtonHovered}
                            setIsButtonHovered={setIsButtonHovered}
                            isSendMemoBtnVisible={isSendMemoBtnVisible}
                        />
                    </Stack>
                </SocketProvider>
            </OutsideWrapper>
        </>
    );
};

export default WaitingAreaResponsiveView;
