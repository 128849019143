/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountInfo } from '@azure/msal-browser';
import { ActionButton, DefaultButton, Icon, Stack, Text } from '@fluentui/react';
import { idpService } from 'services/api/identity-provider.service';
import React, { useState, useEffect } from 'react';

import AzureAuthenticationButtonDE from '../azure/azure-authentication-component-DE';
import AzureAuthenticationButtonEN from '../azure/azure-authentication-component-EN';

import campusIcon from 'media/img/campusIcon.png';
import campusLogo from 'media/img/ivcampus-logo.svg';
import ivicosBlack from 'media/img/ivicos-black.svg';
import mail from 'media/img/mail.svg';
import IvicosStrings from '../kits/language/stringKit';
import { useLoginClassNames } from '../styles/login.style';
import { useClassNames } from '../styles/responsiveness';
import { useLanguage } from '../components/context/LanguageContext';
import PasswordlessAccessModal from './PasswordlessAccessModal';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const Login: React.FC<{}> = () => {
    const providedEmail = sessionStorage.getItem('showEmail') === 'true' && sessionStorage.getItem('email') && sessionStorage.getItem('email');
    const useAlternativeEmailService = sessionStorage.getItem('useAlternativeEmailService')
        ? sessionStorage.getItem('useAlternativeEmailService') === 'true'
        : false;

    const [email, setEmail] = useState(providedEmail || '');
    const [, setCurrentUser] = useState<AccountInfo>();
    const [opacityVal, setOpacityVal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [underlineEN, setUnderlineEN] = useState(false);
    const [underlineDE, setUnderlineDE] = useState(false);
    const [underlineClickToKnowMore, setUnderlineClickToKnowMore] = useState(false);
    const [boldEN, setBoldEN] = useState(false);
    const [boldDE, setBoldDE] = useState(false);
    const [openPasswordlessAccessModal, setOpenPasswordlessAccessModal] = useState(false);

    const { selectedLanguageOption, setSelectedLanguageOption }: any = useLanguage();

    const {
        loginPageAlignment,
        loginPageCampusLogo,
        loginPageCampusIcon,
        loginPageMiddleCard,
        loginPageInnerCard,
        loginPageSignIn,
        loginPageInputField,
        loginPageStackContainingMailAndSubmitButton,
        loginPageSubmitButton,
        loginPageSubmitButtonText,
        loginPageLoading
    } = useLoginClassNames();
    const { ivicosLogo } = useClassNames();
    const [requestCharactersCode, setRequestCharactersCode] = useState<string>();
    const [requestCharactersHeader, setRequestCharactersHeader] = useState<string>();
    const [requestCharactersBody, setRequestCharactersBody] = useState<string>();
    const [enteredCode, setEnteredCode] = useState<string>();
    const [codeVerified, setCodeVerified] = useState<boolean>(false);

    // Add these states at the top with other states
    const [verificationWindow, setVerificationWindow] = useState<Window | null>(null);
    const { smallDevice } = useWindowWidth();

    // Modify the verification window HTML content in requestVerificationCharacters
    const requestVerificationCharacters = async () => {
        try {
            const response = await idpService.requestVerificationCharacters();

            if (response) {
                // setRequestCharactersCode(response.characters);
                // setRequestCharactersHeader(response.request_character?.header_request_character);
                // setRequestCharactersBody(response.request_character?.body_request_character);

                // More precise window dimensions
                const width = smallDevice ? 280 : 400;
                const height = 450;

                // Calculate center position using screen dimensions
                const screenLeft = window.screenLeft || window.screenX;
                const screenTop = window.screenTop || window.screenY;
                const screenWidth = window.screen.availWidth;
                const screenHeight = window.screen.availHeight;

                const left = Math.round(screenLeft + (screenWidth - width) / 2);
                const top = Math.round(screenTop + (screenHeight - height) / 2);

                const verifyWindow = window.open(
                    '',
                    'Verification Code',
                    `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no,status=no,titlebar=yes,toolbar=no,menubar=no,location=no`
                );

                if (verifyWindow) {
                    verifyWindow.moveTo(left, top);
                    setVerificationWindow(verifyWindow);
                    verifyWindow.document.write(`
                        <html>
                            <head>
                                <title>Verification Code</title>
                                <style>
                                    body {
                                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
                                        margin: 0;
                                        padding: 0;
                                        background: #1c1c1e;
                                        height: 100vh;
                                        overflow: hidden;
                                    }
                                    .window {
                                        background: white;
                                        border-radius: 6px;
                                        overflow: hidden;
                                        box-shadow: 0 0 10px rgba(0,0,0,0.2);
                                    }
                                    .content {
                                        padding: 30px;
                                        text-align: center;
                                        position: relative;
                                    }
                                    h2 {
                                        font-size: 18px;
                                        margin: 0 0 20px 0;
                                        color: #333;
                                    }
                                    .code {
                                        display: flex;
                                        justify-content: center;
                                        gap: 8px;
                                        position: relative;
                                        padding: 15px;
                                        background: rgba(255,255,255,0.9);
                                        border-radius: 8px;
                                    }
                                    .code::before {
                                        content: '';
                                        position: absolute;
                                        top: -5px;
                                        left: -5px;
                                        right: -5px;
                                        bottom: -5px;
                                        background: repeating-linear-gradient(
                                            45deg,
                                            rgba(255,255,255,0.1) 0px,
                                            rgba(255,255,255,0.1) 2px,
                                            rgba(0,0,0,0.05) 2px,
                                            rgba(0,0,0,0.05) 4px
                                        );
                                        pointer-events: none;
                                        border-radius: 8px;
                                    }
                                    .code::after {
                                        content: '';
                                        position: absolute;
                                        inset: 0;
                                        background: linear-gradient(
                                            90deg,
                                            transparent 0%,
                                            rgba(255,255,255,0.4) 45%,
                                            rgba(255,255,255,0.4) 55%,
                                            transparent 100%
                                        );
                                        animation: shine 3s linear infinite;
                                        pointer-events: none;
                                        border-radius: 8px;
                                    }
                                    @keyframes shine {
                                        from { transform: translateX(-200%); }
                                        to { transform: translateX(200%); }
                                    }
                                    .code-char {
                                        position: relative;
                                        width: 20px;
                                        height: 30px;
                                        background: linear-gradient(45deg, #fff 85%, #eee 90%);
                                        border: 1px solid #ddd;
                                        border-radius: 4px;
                                        transform: perspective(100px) rotateX(10deg) rotateY(var(--skew));
                                        box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
                                        user-select: none;
                                        -webkit-user-select: none;
                                        -webkit-touch-callout: none;
                                        overflow: hidden;
                                    }
                                    .code-char::before {
                                        content: attr(data-char);
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%)
                                            rotate(var(--rotation))
                                            skew(var(--skew-x), var(--skew-y));
                                        font-family: monospace;
                                        font-size: 24px;
                                        color: #444;
                                        text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
                                        filter: blur(var(--blur));
                                        opacity: var(--opacity);
                                        mix-blend-mode: multiply;
                                    }
                                    .code-char::after {
                                        content: '';
                                        position: absolute;
                                        inset: 0;
                                        background-image: var(--noise);
                                        opacity: 0.1;
                                        mix-blend-mode: overlay;
                                        pointer-events: none;
                                    }
                                    .input-group {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        gap: 12px;
                                        margin-top: 20px;
                                    }
                                    input {
                                        width: 120px;
                                        padding: 8px;
                                        border: 1px solid #0078d4;
                                        border-radius: 4px;
                                        font-size: 14px;
                                        text-align: center;
                                    }
                                    button {
                                        width: 120px;
                                        padding: 8px 16px;
                                        background-color: #0078d4;
                                        color: white;
                                        border: none;
                                        border-radius: 4px;
                                        cursor: pointer;
                                        font-size: 14px;
                                        transition: background-color 0.2s;
                                    }
                                    button:hover {
                                        background-color: #106ebe;
                                    }
                                    .security-pattern {
                                        position: absolute;
                                        inset: 0;
                                        background-image: repeating-linear-gradient(
                                            -45deg,
                                            transparent,
                                            transparent 3px,
                                            rgba(0,0,0,0.02) 3px,
                                            rgba(0,0,0,0.02) 6px
                                        );
                                        pointer-events: none;
                                    }
                                    .new-since {
                                        font-size: 12px;
                                        margin-bottom: 16px;
                                    }
                                    .ivicos-black {
                                        width: 3%;
                                        margin-bottom: 16px;
                                    }
                                    .ivicos-logo {
                                        height: 30px;
                                        margin-bottom: 32px;
                                    }
                                </style>
                            </head>
                            <body>
                                <div class="window">
                                    <div class="content">
                                        <div class="security-pattern"></div>
                                        <img src="${campusLogo}" alt="ivicos-black" class="ivicos-logo">
                                        <h2>${IvicosStrings.toReceiveTheMagicLinkPleaseEnterTheCodeBelowPart1}<br> ${
                        IvicosStrings.toReceiveTheMagicLinkPleaseEnterTheCodeBelowPart2
                    }</h2>
                                        <p class="new-since">${IvicosStrings.newSince}</p>
                                        <div class="code">
                                            ${Array.from(response.characters)
                                                .map(
                                                    (char, i) => `
                                                <div class="code-char"
                                                    data-char="${char}"
                                                    style="--rotation: ${Math.random() * 40 - 20}deg;
                                                           --skew: ${Math.random() * 45 - 22.5}deg;
                                                           --skew-x: ${Math.random() * 20 - 10}deg;
                                                           --skew-y: ${Math.random() * 20 - 10}deg;
                                                           --blur: ${Math.random() * 0.7 + 0.2}px;
                                                           --opacity: ${Math.random() * 0.2 + 0.8};
                                                           --contrast: ${Math.random() * 30 + 70}%">
                                                </div>
                                            `
                                                )
                                                .join('')}
                                        </div>
                                        <div class="input-group">
                                            <input type="text" id="codeInput" maxlength="4" placeholder="${
                                                IvicosStrings.toReceiveTheMagicLinkPleaseEnterTheCodeBelowPart3
                                            }">
                                            <button onclick="verifyCode()">${IvicosStrings.toReceiveTheMagicLinkPleaseEnterTheCodeBelowPart4}</button>
                                        </div>
                                    </div>
                                </div>
                                <script>
                                    function generateNoisePattern() {
                                        const canvas = document.createElement('canvas');
                                        const ctx = canvas.getContext('2d');
                                        canvas.width = 50;
                                        canvas.height = 50;

                                        const imageData = ctx.createImageData(50, 50);
                                        const data = imageData.data;

                                        for (let i = 0; i < data.length; i += 4) {
                                            const noise = Math.random() * 255;
                                            data[i] = noise;     // red
                                            data[i+1] = noise;   // green
                                            data[i+2] = noise;   // blue
                                            data[i+3] = Math.random() * 50;  // alpha
                                        }

                                        ctx.putImageData(imageData, 0, 0);
                                        return canvas.toDataURL();
                                    }

                                    // Apply unique noise pattern to each character
                                    document.querySelectorAll('.code-char').forEach(char => {
                                        char.style.setProperty('--noise', \`url(\${generateNoisePattern()})\`);
                                    });

                                    const startTime = Date.now();
                                    let hasMouseMoved = false;
                                    let mouseEvents = 0;
                                    let keyPressTimestamps = [];

                                    document.addEventListener('mousemove', () => {
                                        hasMouseMoved = true;
                                        mouseEvents++;
                                    });

                                    let focusEvents = 0;
                                    const input = document.getElementById('codeInput');

                                    input.addEventListener('focus', () => focusEvents++);
                                    input.addEventListener('blur', () => focusEvents++);

                                    input.addEventListener('keypress', (e) => {
                                        keyPressTimestamps.push(Date.now());
                                    });

                                    const verificationCode = '${response.characters}';
                                    const headerRequestCharacter = '${response.request_character.header_request_character}';
                                    const bodyRequestCharacter = '${response.request_character.body_request_character}';

                                    function verifyCode() {
                                        const timeElapsed = Date.now() - startTime;
                                        const input = document.getElementById('codeInput').value;

                                        const h2Element = document.querySelector('h2');

                                        function showErrorMessage() {
                                            h2Element.innerHTML = '${IvicosStrings.toReceiveTheMagicLinkPleaseEnterTheCodeBelowPart5}';
                                            h2Element.style.color = '#dc3545';
                                            document.getElementById('codeInput').value = '';

                                        }

                                        // First check if code matches
                                        if (input !== verificationCode) {
                                            showErrorMessage();
                                            return;
                                        }

                                        // Bot detection checks
                                        if (timeElapsed < 1500) {
                                            window.opener.postMessage({
                                                type: 'CODE_INVALID',
                                                verified: false,
                                                reason: 'Input too fast - suspected bot'
                                            }, '*');
                                            window.close();
                                            return;
                                        }

                                        if (!hasMouseMoved || mouseEvents < 3) {
                                            window.opener.postMessage({
                                                type: 'CODE_INVALID',
                                                verified: false,
                                                reason: 'No mouse movement detected'
                                            }, '*');
                                            window.close();
                                            return;
                                        }

                                        if (focusEvents < 2) {
                                            window.opener.postMessage({
                                                type: 'CODE_INVALID',
                                                verified: false,
                                                reason: 'Suspicious input behavior'
                                            }, '*');
                                            window.close();
                                            return;
                                        }

                                        // Analyze typing patterns
                                        if (keyPressTimestamps.length > 1) {
                                            const intervals = [];
                                            for (let i = 1; i < keyPressTimestamps.length; i++) {
                                                intervals.push(keyPressTimestamps[i] - keyPressTimestamps[i-1]);
                                            }

                                            // Check for too consistent typing (bot-like behavior)
                                            const avgInterval = intervals.reduce((a,b) => a + b, 0) / intervals.length;
                                            const tooConsistent = intervals.every(interval =>
                                                Math.abs(interval - avgInterval) < 50
                                            );

                                            if (tooConsistent) {
                                                window.opener.postMessage({
                                                    type: 'CODE_INVALID',
                                                    verified: false,
                                                    reason: 'Suspicious typing pattern'
                                                }, '*');
                                                window.close();
                                                return;
                                            }
                                        }

                                        const result = btoa(JSON.stringify({
                                            code: input,
                                            verified: input === verificationCode,
                                            timestamp: Date.now(),
                                            mouseEvents,
                                            focusEvents,
                                            timeElapsed,
                                            keyPressTimestamps,
                                            headerRequestCharacter,
                                            bodyRequestCharacter
                                        }));

                                        window.opener.postMessage({
                                            type: 'CODE_VERIFY',
                                            data: result
                                        }, '*');

                                        window.close();
                                    }

                                    input.focus();
                                </script>
                            </body>
                        </html>
                    `);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            // setRequestCharactersCode('');
            // setRequestCharactersHeader('');
            // setRequestCharactersBody('');
        }
    };

    // Update the message handler in useEffect
    // useEffect(() => {
    //     const handleMessage = (event: MessageEvent) => {
    //         if (event.data.type === 'CODE_VERIFY') {
    //             try {
    //                 const data = JSON.parse(atob(event.data.data));

    //                 // Validate the verification data
    //                 if (Date.now() - data.timestamp > 5000) {
    //                     setCodeVerified(false);
    //                     return;
    //                 }

    //                 if (data.mouseEvents < 3 || data.focusEvents < 2) {
    //                     setCodeVerified(false);
    //                     return;
    //                 }

    //                 setCodeVerified(data.verified);
    //                 setEnteredCode(data.code);
    //             } catch (e) {
    //                 setCodeVerified(false);
    //             }
    //         }
    //     };

    //     window.addEventListener('message', handleMessage);
    //     return () => window.removeEventListener('message', handleMessage);
    // }, []);

    // const onSubmit = async (event: any) => {
    //     event.preventDefault();

    //     await requestVerificationCharacters();

    //     if (email.trim().length > 0 && !showErrorMessage) {
    //         sessionStorage.setItem('email', email.trim());
    //         setShowLoading(true);
    //         sendMagicLink();
    //         sessionStorage.removeItem('useAlternativeEmailService');
    //         sessionStorage.removeItem('showEmail');
    //     } else {
    //         setShowErrorMessage(true);
    //     }
    // };

    const onSubmit = async (event: any) => {
        event.preventDefault();

        if (email.trim().length === 0 || showErrorMessage) {
            setShowErrorMessage(true);
            return;
        }

        // First request verification code
        await requestVerificationCharacters();

        // Don't proceed until verification is complete
        // The actual sending of magic link will happen after verification
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === 'CODE_VERIFY') {
                try {
                    const data = JSON.parse(atob(event.data.data));

                    if (data.mouseEvents < 3 || data.focusEvents < 2) {
                        setCodeVerified(false);
                        return;
                    }

                    if (data.timeElapsed < 3000) {
                        console.log('🚀probably a bot', data.timeElapsed);
                        setCodeVerified(false);
                        return;
                    } else {
                        console.log('🚀probably a human', data.timeElapsed);
                    }

                    setCodeVerified(data.verified);
                    setEnteredCode(data.code);
                    console.log('🚀 ~ handleMessage ~ data:', data, JSON.stringify(data.request_character, null, 2));

                    // If verification was successful, proceed with magic link
                    if (data.verified && data.headerRequestCharacter && data.bodyRequestCharacter) {
                        setRequestCharactersHeader(data.headerRequestCharacter);
                        setRequestCharactersBody(data.bodyRequestCharacter);
                    }
                } catch (e) {
                    setCodeVerified(false);
                }
            } else if (event.data.type === 'CODE_INVALID') {
                setCodeVerified(false);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [email]);

    useEffect(() => {
        if (!codeVerified || !requestCharactersHeader || !requestCharactersBody) {
            console.error('Missing required verification data:', {
                codeVerified,
                requestCharactersHeader,
                requestCharactersBody
            });
            return;
        }
        setShowLoading(true);
        sessionStorage.setItem('email', email.trim());
        sendMagicLink(requestCharactersHeader, requestCharactersBody);
        sessionStorage.removeItem('useAlternativeEmailService');
        sessionStorage.removeItem('showEmail');
    }, [codeVerified, requestCharactersHeader, requestCharactersBody]);

    const sendMagicLink = (header: any, body: any) => {
        // First check if values exist

        idpService
            .magicLink(email.trim(), selectedLanguageOption, useAlternativeEmailService, header, body)
            .then(() => {
                window.location.href = '/newUserAwaitingConfirmation';
                // Clear values only after successful request
                // setRequestCharactersCode('');
                // setRequestCharactersHeader('');
                // setRequestCharactersBody('');
                // setCodeVerified(false);
                // setEnteredCode('');
            })
            .catch((error) => {
                console.error('Magic link error:', error);
                window.location.href = '/newUserSomethingWrong';
            });
    };

    const onAuthenticated = async (userAccountInfo: AccountInfo) => {
        setCurrentUser(userAccountInfo);

        const token = sessionStorage.getItem('msToken');

        if (token != null) {
            idpService.authUserMS(token).then(() => {
                window.location.href = '/welcome';
            });
        } else {
            console.log('login error');
        }
    };

    const validateEmail = (emailValue: string) => {
        const emailRegex = /\S+@\S+\.\S+/;

        if (emailRegex.test(emailValue)) {
            setShowErrorMessage(false);
        } else {
            setShowErrorMessage(true);
        }
        if (emailValue === '') {
            setShowErrorMessage(true);
        }
    };

    return (
        <Stack>
            <Stack className={loginPageAlignment}>
                <Stack className={loginPageCampusLogo}>
                    <img src={campusLogo} alt="logo" />
                </Stack>

                <Stack className={loginPageCampusIcon}>
                    <img src={campusIcon} alt="campus icon" width={91.56} />
                </Stack>

                <Stack
                    style={{
                        height: selectedLanguageOption === 'en' ? 465 : 485,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack className={loginPageMiddleCard}>
                        {!showLoading ? (
                            <Stack className={loginPageInnerCard}>
                                <Text className={loginPageSignIn} variant="xxLarge">
                                    {IvicosStrings.signInTitle}
                                </Text>
                                <Text style={{ marginTop: 15 }} variant="small">
                                    {IvicosStrings.newToPassWordlessAcesss}{' '}
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            textDecoration: underlineClickToKnowMore ? 'underline' : 'none'
                                        }}
                                        onMouseEnter={() => {
                                            setUnderlineClickToKnowMore(true);
                                        }}
                                        onMouseLeave={() => {
                                            setUnderlineClickToKnowMore(false);
                                        }}
                                        onClick={() => setOpenPasswordlessAccessModal(true)}
                                    >
                                        {IvicosStrings.clickHereToKnowMore}
                                    </span>
                                </Text>
                                {openPasswordlessAccessModal && (
                                    <PasswordlessAccessModal
                                        openPasswordlessAccessModal={openPasswordlessAccessModal}
                                        setOpenPasswordlessAccessModal={setOpenPasswordlessAccessModal}
                                    />
                                )}

                                <form
                                    onSubmit={(event) => {
                                        onSubmit(event);
                                    }}
                                >
                                    <input
                                        type="text"
                                        id="email"
                                        placeholder={IvicosStrings.emailPlaceHolder}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            validateEmail(e.target.value);
                                        }}
                                        className={loginPageInputField}
                                    />

                                    {/* <Stack>
                                        <DefaultButton
                                            onClick={() => {
                                                requestVerificationCharacters();
                                            }}
                                        >
                                            Request code
                                        </DefaultButton>
                                    </Stack>

                                    {enteredCode && (
                                        <Stack>
                                            <Text>
                                                {codeVerified ? (
                                                    <>
                                                        <Icon iconName="CheckMark" styles={{ root: { color: 'green', fontSize: 20 } }} /> Code verified
                                                    </>
                                                ) : (
                                                    <>
                                                        <Icon iconName="Cancel" styles={{ root: { color: 'red', fontSize: 20 } }} /> Code invalid
                                                    </>
                                                )}
                                            </Text>
                                        </Stack>
                                    )} */}

                                    <Stack
                                        className={loginPageStackContainingMailAndSubmitButton}
                                        style={{
                                            opacity: opacityVal ? 0.7 : 1
                                        }}
                                    >
                                        <img src={mail} alt="" style={{ marginRight: 10 }} />
                                        <button
                                            type="submit"
                                            onMouseOver={() => {
                                                setOpacityVal(true);
                                            }}
                                            onMouseOut={() => {
                                                setOpacityVal(false);
                                            }}
                                            className={loginPageSubmitButton}
                                        >
                                            <Text className={loginPageSubmitButtonText} variant="large">
                                                {IvicosStrings.emailButtonText}
                                            </Text>
                                        </button>
                                    </Stack>
                                </form>

                                {showErrorMessage ? (
                                    <Text
                                        variant="small"
                                        style={{
                                            marginTop: 20,
                                            color: 'red'
                                        }}
                                    >
                                        {IvicosStrings.emailErrorMessage}
                                    </Text>
                                ) : (
                                    <Text
                                        variant="small"
                                        style={{
                                            marginTop: 20
                                        }}
                                    >
                                        {IvicosStrings.loginPageDescription3}
                                    </Text>
                                )}

                                <Stack
                                    style={{
                                        marginTop: 20,
                                        flexDirection: 'row'
                                    }}
                                >
                                    {selectedLanguageOption === 'en' && <Text>_______________________</Text>}
                                    {selectedLanguageOption === 'de' && <Text>______________________</Text>}

                                    <Text style={{ marginLeft: 19, marginRight: 19, marginTop: 5 }}>{IvicosStrings.orOptionTitle}</Text>
                                    {selectedLanguageOption === 'en' && <Text>_______________________</Text>}
                                    {selectedLanguageOption === 'de' && <Text>______________________</Text>}
                                </Stack>
                                <Stack
                                    style={{
                                        width: 315,
                                        height: 43,
                                        marginTop: 30,
                                        borderRadius: 5
                                    }}
                                >
                                    {selectedLanguageOption === 'en' && <AzureAuthenticationButtonEN onAuthenticated={onAuthenticated} type="submit" />}
                                    {selectedLanguageOption === 'de' && <AzureAuthenticationButtonDE onAuthenticated={onAuthenticated} type="submit" />}
                                </Stack>
                            </Stack>
                        ) : (
                            <Text variant="xxLarge" className={loginPageLoading}>
                                {IvicosStrings.loading}
                            </Text>
                        )}
                    </Stack>
                </Stack>

                <Stack
                    style={{
                        height: 40,
                        width: 375,
                        marginTop: 50,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Text variant="large">
                        <ActionButton
                            className="pressable--transparent language-login-button"
                            styles={{
                                root: {
                                    textDecoration: underlineEN ? 'underline' : 'none',
                                    fontSize: 18,
                                    fontWeight: boldEN || selectedLanguageOption === 'en' ? 'bold' : 'normal',
                                    cursor: 'pointer',
                                    padding: 0,
                                    margin: 0
                                }
                            }}
                            onMouseEnter={() => {
                                setUnderlineEN(true);
                            }}
                            onMouseLeave={() => {
                                setUnderlineEN(false);
                            }}
                            onClick={() => {
                                setSelectedLanguageOption('en');
                                IvicosStrings.setLanguage('en');
                                localStorage.setItem('UserLanguageChoice', 'en');
                                setBoldEN(true);
                                setBoldDE(false);
                            }}
                        >
                            English
                        </ActionButton>
                        |
                        <ActionButton
                            className="pressable--transparent language-login-button"
                            styles={{
                                root: {
                                    textDecoration: underlineDE ? 'underline' : 'none',
                                    fontSize: 18,
                                    fontWeight: boldDE || selectedLanguageOption === 'de' ? 'bold' : 'normal',
                                    cursor: 'pointer',
                                    padding: 0,
                                    margin: 0
                                }
                            }}
                            onMouseEnter={() => {
                                setUnderlineDE(true);
                            }}
                            onMouseLeave={() => {
                                setUnderlineDE(false);
                            }}
                            onClick={() => {
                                setSelectedLanguageOption('de');
                                IvicosStrings.setLanguage('de');
                                localStorage.setItem('UserLanguageChoice', 'de');
                                setBoldDE(true);
                                setBoldEN(false);
                            }}
                        >
                            Deutsch
                        </ActionButton>
                    </Text>
                </Stack>
                <Stack
                    style={{
                        marginTop: 50,
                        marginBottom: 15
                    }}
                    className={ivicosLogo}
                >
                    <Stack
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 5
                        }}
                    >
                        <Text
                            variant="small"
                            style={{
                                alignSelf: 'end',
                                fontWeight: 'bold',
                                marginRight: 2
                            }}
                        >
                            {IvicosStrings.developedByTitle1}
                        </Text>
                        <Stack>
                            <img src={ivicosBlack} alt="ivicos-black-logo" width={75} height={20} />
                        </Stack>
                    </Stack>
                    <Text
                        variant="smallPlus"
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        {IvicosStrings.developedByTitle2}
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Login;
