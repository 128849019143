import React from 'react';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useWaitingAreaState } from './WaitingArea.state';
import WaitingAreaView from './WaitingArea.view';
import WaitingAreaResponsiveView from './WaitingAreaResponsive.view';
import IvicosStrings from 'kits/language/stringKit';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';

const WaitingArea = (): any => {
    const state = useWaitingAreaState();
    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <>
            {state.isLoadingDialogVisible === 'true' && (
                <LoadingDialog loadingStrings={[IvicosStrings.loadingStringWaitingAreas]} view="full" typewriter={false} />
            )}
            <NotificationsLayer />
            {smallDevice || mediumDevice ? (
                <WaitingAreaResponsiveView
                    {...{
                        ...state,
                        ...{
                            areaId: state.currentAreaId
                        }
                    }}
                />
            ) : (
                <WaitingAreaView
                    {...{
                        ...state,
                        ...{
                            areaId: state.currentAreaId
                        }
                    }}
                />
            )}
        </>
    );
};

export default WaitingArea;
