import { DirectionalHint, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';

interface IBasicListState {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    onBasicListChange: (ev: any, option: any) => void;
}

export const useBasicListState: (onChange: any) => IBasicListState = (onChange) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const onBasicListChange = (ev: any, item: any) => {
        onChange(item);
    };

    return {
        isCalloutVisible,
        styles,
        toggleIsCalloutVisible,
        buttonId,
        directionalHint,
        onBasicListChange
    };
};
