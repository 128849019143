import { createTheme } from '@fluentui/react';

export const DarkTheme = createTheme({
    palette: {
        themePrimary: '#40acff',
        themeLighterAlt: '#03070a',
        themeLighter: '#0a1c29',
        themeLight: '#13344d',
        themeTertiary: '#266799',
        themeSecondary: '#3897e0',
        themeDarkAlt: '#53b4ff',
        themeDark: '#6ec0ff',
        themeDarker: '#94d1ff',
        neutralLighterAlt: '#212121',
        neutralLighter: '#2a2a2a',
        neutralLight: '#393939',
        neutralQuaternaryAlt: '#424242',
        neutralQuaternary: '#494949',
        neutralTertiaryAlt: '#686868',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#171717'
    }
});
