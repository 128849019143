import IvicosStrings from './stringKit';

export const getPresenceTooltip = (userCount: number, visitorCount: number): string => {
    const strings = IvicosStrings.areaPresenceTooltip;

    // Early return if no presence
    if (userCount === 0 && visitorCount === 0) return '';

    // Helper functions
    const getVerb = (total: number) => (total === 1 ? strings.is : strings.are);
    const getMemberText = (count: number) => (count === 1 ? strings.member : strings.members);
    const getVisitorText = (count: number) => (count === 1 ? strings.visitor : strings.visitors);

    // Build the message based on presence
    const hasBoth = userCount > 0 && visitorCount > 0;
    const template = hasBoth ? strings.bothPresent : userCount > 0 ? strings.membersOnly : strings.visitorsOnly;

    // Replace placeholders
    return template
        .replace('{verb}', getVerb(hasBoth ? userCount + visitorCount : userCount || visitorCount))
        .replace('{memberCount}', userCount.toString())
        .replace('{members}', getMemberText(userCount))
        .replace('{visitorCount}', visitorCount.toString())
        .replace('{visitors}', getVisitorText(visitorCount))
        .replace('{count}', (userCount || visitorCount).toString());
};
