import {
    DefaultButton,
    Depths,
    Dropdown,
    IProcessedStyleSet,
    ITextFieldStyles,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    Text,
    TextField
} from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import {
    IJitsiDevice,
    IJitsiDeviceConfig,
    IJitsiDeviceList,
    IJitsiDeviceType
} from 'features/Rooms/RoomView/DeviceSettingsAtVisitorEntrance/DeviceSettingsAtVisitorEntrance';
import { getTextColorForBackground } from 'kits/colorKit';
import { Settings, VisitorsAudio, VisitorsVideo } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useState } from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import AudioMeter from './AudioMeter';
import { ILandingPageSideCardProps } from './LandingPageSideCard';

export interface ILandingPageSideCardMobileProps extends ILandingPageSideCardProps {
    backgroundColor: string;
    buttonId: string;
    buttonIdAudio: string;
    isEnterNameCalloutVisible: boolean;
    setIsEnterNameCalloutVisible: (visible: boolean) => void;
    landingPageVisitorName: string;
    setLandingPageVisitorName: (name: string) => void;
    textFieldStyles: Partial<ITextFieldStyles>;
    styles: IProcessedStyleSet<{
        callout: { width: number; padding: string };
    }>;
    isAudioCalloutVisible: boolean;
    setIsAudioCalloutVisible: (visible: boolean) => void;
    availableDevices: IJitsiDeviceList;
    currentDevices: IJitsiDeviceConfig;
    updateVisitorsDeviceSettings: (type: IJitsiDeviceType, device: IJitsiDevice) => void;
    customLogo: string | undefined;
    IvicosLogo: string;
    logoDimensions: { width: number; height: number };
}

const LandingPageSideCardMobile: React.FunctionComponent<ILandingPageSideCardMobileProps> = ({
    eventName,
    goInClicked,
    backgroundColor,
    landingPageVisitorName,
    setLandingPageVisitorName,
    textFieldStyles,
    availableDevices,
    currentDevices,
    updateVisitorsDeviceSettings,
    customLogo,
    IvicosLogo,
    logoDimensions
}) => {
    const { smallDevice, mediumDevice } = useWindowWidth();

    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const renderAudioSection = () => (
        <Stack tokens={{ childrenGap: 8 }}>
            <Stack horizontal horizontalAlign="space-between" style={{ marginTop: '20px' }}>
                <Stack horizontal>
                    <IC variant="dark" size={16}>
                        <VisitorsAudio />
                    </IC>
                    <Text style={{ fontWeight: 700, marginLeft: 6 }} aria-label="visitor-landing-page-audio">
                        Audio
                    </Text>
                </Stack>
            </Stack>
            <Stack style={{ marginBottom: 10 }} aria-label="visitor-landing-page-audio-dropdown">
                <Stack style={{ margin: '10px 0' }} aria-label="visitor-landing-page-audio-meter">
                    <AudioMeter />
                </Stack>
                <Dropdown
                    label={IvicosStrings.audioInputDeviceTitle}
                    selectedKey={currentDevices?.audioInput && currentDevices.audioInput.deviceId}
                    onChange={(e, i) => updateVisitorsDeviceSettings('audioInput', i?.data)}
                    placeholder={IvicosStrings.selectDevicePlaceholder}
                    options={
                        availableDevices?.audioInput?.map((device: any) => ({
                            key: device.deviceId,
                            text: device.label,
                            data: device
                        })) || []
                    }
                    styles={{}}
                />
                <Dropdown
                    label={IvicosStrings.audioOutputDevice}
                    selectedKey={currentDevices?.audioOutput && currentDevices.audioOutput.deviceId}
                    onChange={(e, i) => updateVisitorsDeviceSettings('audioOutput', i?.data || {})}
                    placeholder={IvicosStrings.selectDevicePlaceholder}
                    options={
                        availableDevices?.audioOutput?.map((device: any) => ({
                            key: device.deviceId,
                            text: device.label,
                            data: device
                        })) || []
                    }
                    styles={{}}
                />
            </Stack>
        </Stack>
    );

    const renderVideoSection = () => (
        <Stack tokens={{ childrenGap: 8 }}>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <IC variant="dark" size={16}>
                    <VisitorsVideo />
                </IC>
                <Text style={{ fontWeight: 700 }} aria-label="visitor-landing-page-video">
                    Video
                </Text>
            </Stack>
            <Stack aria-label="visitor-landing-page-video-dropdown">
                <Dropdown
                    selectedKey={currentDevices?.videoInput && currentDevices.videoInput.deviceId}
                    onChange={(e, i) => updateVisitorsDeviceSettings('videoInput', i?.data)}
                    placeholder={IvicosStrings.selectDevicePlaceholder}
                    options={
                        availableDevices?.videoInput?.map((device: any) => ({
                            key: device.deviceId,
                            text: device.label,
                            data: device
                        })) || []
                    }
                    styles={{}}
                />
            </Stack>
        </Stack>
    );

    const renderAudioVideoPanel = () => (
        <Panel
            isOpen={isPanelOpen}
            onDismiss={() => setIsPanelOpen(false)}
            headerText={IvicosStrings.landingPageHeaderTitle}
            closeButtonAriaLabel="Close"
            type={PanelType.medium}
        >
            <Stack tokens={{ childrenGap: 16 }}>
                {renderAudioSection()}
                {renderVideoSection()}
            </Stack>
        </Panel>
    );

    return (
        <>
            <Stack style={{ boxShadow: Depths.depth64, background: backgroundColor }}>
                {/* HEADER (always visible) */}
                <Stack
                    aria-label="visitor-landing-page-logo"
                    key="brand"
                    style={{
                        height: '50px',
                        width: logoDimensions.width,
                        backgroundImage: `url(${customLogo || IvicosLogo})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left center',
                        margin: '8px 20px'
                    }}
                />

                {/* EVENT NAME */}
                <Stack horizontal verticalAlign="center" style={{ padding: '0px 22px' }} aria-label="visitor-landing-page-event-name">
                    <Text
                        style={{
                            color: getTextColorForBackground(backgroundColor),
                            width: '100%',
                            wordBreak: 'break-word',
                            fontWeight: 700
                        }}
                        variant="large"
                    >
                        {eventName}
                    </Text>
                </Stack>

                {/* ENTER NAME */}
                <Stack verticalAlign="center" style={{ padding: '8px 22px' }}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Text
                            style={{
                                lineHeight: '30px',
                                color: getTextColorForBackground(backgroundColor),
                                fontWeight: 700
                            }}
                            variant="medium"
                            aria-label="visitor-landing-page-enter-name"
                        >
                            {IvicosStrings.landingPageEnterName}
                        </Text>
                    </Stack>

                    <TextField
                        value={landingPageVisitorName}
                        onChange={(event, newValue) => typeof newValue === 'string' && setLandingPageVisitorName(newValue)}
                        styles={textFieldStyles}
                        placeholder="Campus Visitor"
                        data-test-id="visitor-landing-page-enter-name-text-field"
                    />
                    <Text variant="small" aria-label="visitor-landing-page-privacy-hint" style={{ color: getTextColorForBackground(backgroundColor) }}>
                        {IvicosStrings.landingPagePrivacyHint}
                    </Text>
                </Stack>

                {/* FOOTER */}
                <Stack horizontalAlign="end" horizontal verticalAlign="center" style={{ padding: '8px 20px' }} tokens={{ childrenGap: 8 }}>
                    {(smallDevice || mediumDevice) && (
                        <Stack horizontal tokens={{ childrenGap: 6 }}>
                            <DefaultButton
                                styles={{ root: { minWidth: '40px', marginTop: 4, marginBottom: 4 } }}
                                onRenderIcon={() => (
                                    <IC variant="dark" size={24}>
                                        <Settings />
                                    </IC>
                                )}
                                aria-label="audio-video-settings"
                                onClick={() => setIsPanelOpen(true)}
                            />
                        </Stack>
                    )}
                    <PrimaryButton
                        text={IvicosStrings.landingPageGoIn}
                        onClick={() => goInClicked(landingPageVisitorName)}
                        aria-label="visitor-landing-page-go-in"
                    />
                </Stack>
            </Stack>
            {renderAudioVideoPanel()}
        </>
    );
};

export default LandingPageSideCardMobile;
