import React from 'react';

import { getTheme, Stack } from '@fluentui/react';
import { IResource } from 'kits/apiKit3/legacy';

import { useWindowWidth } from 'shared-state/display-options/hooks';
import defaultVisitorEntranceBG from '../../../../media/img/default-bgve.jpg';

export interface IOutsideWrapperProps {
    headerActions?: Array<any>;
    campus?: IResource;
    backgroundImage?: string;
    children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const theme = getTheme();

const OutsideWrapper: React.FC<IOutsideWrapperProps> = ({ children, backgroundImage }) => {
    const rootStyle: React.CSSProperties = {
        backgroundImage: `url(${backgroundImage || defaultVisitorEntranceBG})`,
        backgroundColor: theme.palette.neutralLight,
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };

    const rootStyleMobile: React.CSSProperties = {
        backgroundImage: `url(${backgroundImage || defaultVisitorEntranceBG})`,
        backgroundColor: theme.palette.neutralLight,
        height: '100vh',
        width: '100vw',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: 8
    };

    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <Stack style={smallDevice || mediumDevice ? rootStyleMobile : rootStyle}>
            <Stack
                key="content"
                className="js-o-w"
                grow
                horizontal={!smallDevice && !mediumDevice}
                horizontalAlign="center"
                verticalAlign={smallDevice || mediumDevice ? 'start' : 'stretch'}
                style={{ height: 'calc( 100vh - 64px )', minHeight: 'calc( 100vh - 64px )' }}
            >
                {children}
            </Stack>
        </Stack>
    );
};

export default OutsideWrapper;
