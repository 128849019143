import React from 'react';
import 'styles/helpers.css';
import { useRoomViewState } from './RoomView.state';
import RoomViewView from './RoomView.view';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const RoomView: React.FC<{ mobileMenuOpen: boolean; children?: React.ReactNode }> = (props) => {
    const state = useRoomViewState();
    const { smallDevice, mediumDevice } = useWindowWidth();
    const isMeetPath = window.location.pathname.includes('/meet');

    return (
        <>
            {isMeetPath && (smallDevice || mediumDevice) ? (
                <RoomViewView
                    {...{
                        ...state,
                        ...{
                            mobileMenuOpen: props.mobileMenuOpen
                        }
                    }}
                >
                    {props.children}
                </RoomViewView>
            ) : (
                <RoomViewView
                    {...{
                        ...state,
                        ...{
                            mobileMenuOpen: props.mobileMenuOpen
                        }
                    }}
                >
                    {props.children}
                </RoomViewView>
            )}
        </>
    );
};
export default RoomView;
