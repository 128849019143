// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DefaultEffects, Stack } from '@fluentui/react';
import RoomView from 'features/Rooms/RoomView/RoomView';
import React from 'react';
import { IHostPerspectiveWaitingAreaState } from './HostPerspectiveWaitingArea.state';
import { RoomSelectionDialog } from './RoomSelectionDialog/RoomSelectionDialog';
import { useWindowWidth } from 'shared-state/display-options/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HostPerspectiveWaitingAreaView: React.FC<IHostPerspectiveWaitingAreaState> = (props) => {
    const { smallDevice, mediumDevice } = useWindowWidth();
    return smallDevice || mediumDevice ? (
        <Stack horizontal={false} tokens={{ childrenGap: 16 }} horizontalAlign="center" style={{ height: '80%' }}>
            <RoomView mobileMenuOpen={false} />
            <Stack horizontal={false}>
                <RoomSelectionDialog />
            </Stack>
        </Stack>
    ) : (
        <Stack grow horizontal tokens={{ childrenGap: 16 }} style={{ padding: 16 }}>
            <Stack horizontal={false}>
                <RoomSelectionDialog />
            </Stack>
            <RoomView mobileMenuOpen={false} />
        </Stack>
    );
};

export default HostPerspectiveWaitingAreaView;
