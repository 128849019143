import React from 'react';
import { Stack } from '@fluentui/react';
import { SherloqSettings } from 'features/Rooms/Sherloq/SherloqSettings';

export const IntegrationSettings: React.FC = () => {
    return (
        <Stack style={{ margin: 16 }}>
            <SherloqSettings />
        </Stack>
    );
};
