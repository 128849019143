import { getTheme, Stack, FontIcon, DefaultButton, Text } from '@fluentui/react';
import React from 'react';
import IvicosStrings from 'kits/language/stringKit';

export const Error: React.FC<{ errorMessage?: string; onReset: () => void }> = (props) => {
    const { errorMessage, onReset } = props;
    const theme = getTheme();
    return (
        <Stack horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
            <FontIcon style={{ fontSize: 24, height: 24, width: 24, color: theme.palette.red }} iconName="error"></FontIcon>
            <Text style={{ textAlign: 'center' }}>{IvicosStrings.sherloqLabelError}</Text>
            {errorMessage && <Text>{errorMessage}</Text>}
            <DefaultButton className="sherloq-error-back" onClick={onReset}>
                {IvicosStrings.sherloqButtonErrorGoBack}
            </DefaultButton>
        </Stack>
    );
};
