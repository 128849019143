import { ITextFieldStyles, getTheme, mergeStyleSets, mergeStyles } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import IvicosLogo from 'media/logos/iv_campus_logo_white.svg';

import { IvicosColors } from 'kits/colorKit';
import { IIdentityClaims } from 'shared-state/identity/types';
import { OrgBrandingDto } from '../../services/client-api-wrapper/identity_provider/responses.dto';

import {
    aAvailableDevicesInDeviceSettings,
    aCurrentDevicesInDeviceSettings,
    aIsSetAudioDeviceSettingsClicked,
    aUpdatedVisitorsDeviceSettings
} from 'features/Rooms/RoomView/DeviceSettingsAtVisitorEntrance/atom';
import {
    IJitsiDevice,
    IJitsiDeviceConfig,
    IJitsiDeviceList,
    IJitsiDeviceType
} from 'features/Rooms/RoomView/DeviceSettingsAtVisitorEntrance/DeviceSettingsAtVisitorEntrance';
import { aIsAudioDeviceSetVisible } from './atom';
import LandingPageSideCardDesktop from './LandingPageSideCardDesktop.view';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import LandingPageSideCardMobile from './LandingPageSideCardMobile.view';

export interface IUpdatedVisitorsDeviceSettings {
    isVisitorUpdate: boolean | undefined;
    visitorDeviceType: IJitsiDeviceType;
    visitorDevice: IJitsiDevice;
}

export interface ILandingPageSideCardProps {
    eventName: string;
    branding: OrgBrandingDto | undefined;
    videntityClaims: IIdentityClaims | undefined;
    goInClicked: (name?: string) => void;
}

const LandingPageSideCard: React.FunctionComponent<ILandingPageSideCardProps> = ({ eventName, branding, videntityClaims, goInClicked }) => {
    const theme = getTheme();
    const buttonId = useId('callout-button');
    const buttonIdAudio = useId('callout-button');
    const customLogo = branding?.orgLogo;
    const [isEnterNameCalloutVisible, setIsEnterNameCalloutVisible] = useState<boolean>(false);
    const [isAudioCalloutVisible, setIsAudioCalloutVisible] = useState<boolean>(false);
    const [isAudioDeviceSetVisible, setIsAudioDeviceSetVisible] = useRecoilState<boolean>(aIsAudioDeviceSetVisible);
    const availableDevices = useRecoilValue<IJitsiDeviceList>(aAvailableDevicesInDeviceSettings);
    const currentDevices = useRecoilValue<IJitsiDeviceConfig>(aCurrentDevicesInDeviceSettings);
    const [, setUpdatedVisitorsDeviceSettings] = useRecoilState<any>(aUpdatedVisitorsDeviceSettings);
    const [landingPageVisitorName, setLandingPageVisitorName] = useState<string>('');
    const [isSetAudioDeviceSettingsClicked, SetIsSetAudioDeviceSettingsClicked] = useRecoilState(aIsSetAudioDeviceSettingsClicked);
    const { smallDevice, mediumDevice } = useWindowWidth();
    const [logoDimensions, setLogoDimensions] = useState({ width: 150, height: 500 });

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    const textFieldStyles: Partial<ITextFieldStyles> = {
        fieldGroup: { width: '100%', height: 35, border: '1px solid black', padding: '4px 5px', marginTop: 5 }
    };

    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const deviceSettingStyle = mergeStyles({
        marginLeft: 6,
        cursor: 'pointer',
        ':hover': {
            color: theme.palette.themePrimary
        }
    });

    const updateVisitorsDeviceSettings = (type: IJitsiDeviceType, device: IJitsiDevice) => {
        const updatedSettings = {
            isVisitorUpdate: videntityClaims?.roles.includes('unconfirmed_visitor') && videntityClaims.type === 'visitor',
            visitorDeviceType: type,
            visitorDevice: device
        };
        setUpdatedVisitorsDeviceSettings(updatedSettings);
    };

    useEffect(() => {
        const img = new Image();
        let isSubscribed = true;

        img.onload = () => {
            if (isSubscribed) {
                const aspectRatio = img.naturalWidth / img.naturalHeight;
                const calculatedWidth = aspectRatio > 1.2 ? 150 : 50;
                setLogoDimensions({ width: calculatedWidth, height: img.naturalHeight });
            }
        };

        img.src = customLogo || IvicosLogo;

        return () => {
            isSubscribed = false;
        };
    }, [customLogo]);

    return (
        <>
            {!smallDevice && !mediumDevice ? (
                <LandingPageSideCardDesktop
                    eventName={eventName}
                    branding={branding}
                    videntityClaims={videntityClaims}
                    goInClicked={goInClicked}
                    backgroundColor={backgroundColor}
                    buttonId={buttonId}
                    buttonIdAudio={buttonIdAudio}
                    isEnterNameCalloutVisible={isEnterNameCalloutVisible}
                    setIsEnterNameCalloutVisible={setIsEnterNameCalloutVisible}
                    landingPageVisitorName={landingPageVisitorName}
                    setLandingPageVisitorName={setLandingPageVisitorName}
                    textFieldStyles={textFieldStyles}
                    styles={styles}
                    isAudioCalloutVisible={isAudioCalloutVisible}
                    setIsAudioCalloutVisible={setIsAudioCalloutVisible}
                    availableDevices={availableDevices}
                    currentDevices={currentDevices}
                    updateVisitorsDeviceSettings={updateVisitorsDeviceSettings}
                    deviceSettingStyle={deviceSettingStyle}
                    isAudioDeviceSetVisible={isAudioDeviceSetVisible}
                    setIsAudioDeviceSetVisible={setIsAudioDeviceSetVisible}
                    isSetAudioDeviceSettingsClicked={isSetAudioDeviceSettingsClicked}
                    SetIsSetAudioDeviceSettingsClicked={SetIsSetAudioDeviceSettingsClicked}
                    customLogo={customLogo}
                    IvicosLogo={IvicosLogo}
                    logoDimensions={logoDimensions}
                />
            ) : (
                <LandingPageSideCardMobile
                    eventName={eventName}
                    branding={branding}
                    videntityClaims={videntityClaims}
                    goInClicked={goInClicked}
                    backgroundColor={backgroundColor}
                    buttonId={buttonId}
                    buttonIdAudio={buttonIdAudio}
                    isEnterNameCalloutVisible={isEnterNameCalloutVisible}
                    setIsEnterNameCalloutVisible={setIsEnterNameCalloutVisible}
                    landingPageVisitorName={landingPageVisitorName}
                    setLandingPageVisitorName={setLandingPageVisitorName}
                    textFieldStyles={textFieldStyles}
                    styles={styles}
                    isAudioCalloutVisible={isAudioCalloutVisible}
                    setIsAudioCalloutVisible={setIsAudioCalloutVisible}
                    availableDevices={availableDevices}
                    currentDevices={currentDevices}
                    updateVisitorsDeviceSettings={updateVisitorsDeviceSettings}
                    customLogo={customLogo}
                    IvicosLogo={IvicosLogo}
                    logoDimensions={logoDimensions}
                />
            )}
        </>
    );
};

export default LandingPageSideCard;
