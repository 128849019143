import { DefaultEffects, IconButton, Panel, PanelType, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import Badge from 'components/Badge';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import { Memo } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect } from 'react';
import { useIsWaitingAreaMemoDialogOpen } from 'shared-state/display-options/hooks';
import utf8 from 'utf8';
import { IRoomSelecitonDialogState } from './RoomSelectionDialog.state';
import WaitingAreaMemoComposer from './WaitingAreaMemoComposer/WaitingAreaMemoComposer';

export const RoomSelectionDialogMobile: React.FC<IRoomSelecitonDialogState> = ({
    branding,
    eventName,
    visitorName,
    formFields,
    onWaitingRoomFormChange,
    letVisitorInside,
    changes,
    selectedVisitor,
    fetchedRooms,
    activeBtn,
    setActiveBtn,
    selectedArea,
    buttonLabelText,
    backToArea
}) => {
    const theme = useTheme();

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    useEffect(() => {
        if (fetchedRooms.length === 0) {
            setActiveBtn(true);
        }
    }, [fetchedRooms]);

    const [isWaitingAreaMemoDialogOpen, setIsWaitingAreaMemoDialogOpen] = useIsWaitingAreaMemoDialogOpen();
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);

    const handlePanelDismiss = () => {
        setIsPanelOpen(false);
        changes.room = '';
        changes.area.selectedKey = '';
        onWaitingRoomFormChange('', {});
        setActiveBtn(true);
    };

    return (
        <>
            {/* <NotificationsLayer /> */}
            <Stack style={{ width: 340, boxShadow: DefaultEffects.elevation64 }}>
                {/* UPPER CARD WITH EVENT NAME AND VISITOR NAME */}
                <Stack
                    verticalAlign={'center'}
                    style={{ background: backgroundColor, borderRadius: '4px 4px 0px 0px', padding: 20 }}
                    tokens={{ childrenGap: 20 }}
                >
                    <Text
                        variant="xLargePlus"
                        style={{
                            lineHeight: '35px',
                            color: getTextColorForBackground(backgroundColor)
                        }}
                    >
                        {eventName}
                    </Text>
                    <Stack aria-label="meet-area-visitor-badge">
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                            <Badge text={utf8.decode(visitorName || '')} size="50" fontSize="medium" textColor={backgroundColor} />
                            <Text
                                variant="large"
                                style={{
                                    lineHeight: '25px',
                                    fontWeight: 'bold',
                                    wordBreak: 'break-word',
                                    color: getTextColorForBackground(backgroundColor)
                                }}
                            >
                                {utf8.decode(visitorName || '')}
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack style={{ width: '100%' }} horizontal horizontalAlign="end">
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <IconButton
                                aria-label="back-to-area-button"
                                onClick={backToArea}
                                iconProps={{ iconName: 'Leave' }}
                                styles={{ root: { width: 32, height: 32, backgroundColor: theme.palette.white, color: theme.palette.black } }}
                            />
                            <IconButton
                                onRenderIcon={() => <Memo />}
                                onClick={() => setIsWaitingAreaMemoDialogOpen(true)}
                                styles={{ root: { width: 32, height: 32, backgroundColor: theme.palette.white } }}
                            />
                            <PrimaryButton text={IvicosStrings.manageEntranceButton} onClick={() => setIsPanelOpen(true)} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {isWaitingAreaMemoDialogOpen && (
                <WaitingAreaMemoComposer
                    recipientName={visitorName || ''}
                    recipientId={selectedVisitor || ''}
                    sentBy="host"
                    isOpen={isWaitingAreaMemoDialogOpen}
                    onClose={() => setIsWaitingAreaMemoDialogOpen(false)}
                />
            )}
            <Panel
                isOpen={isPanelOpen}
                onDismiss={handlePanelDismiss}
                type={PanelType.medium}
                headerText={IvicosStrings.directVisitorToRoom}
                onRenderFooter={() => (
                    <Stack style={{ padding: 16 }} horizontal horizontalAlign="end">
                        <PrimaryButton
                            aria-label="meet-area-let-them-in-button"
                            text={buttonLabelText()}
                            disabled={activeBtn}
                            onClick={() => letVisitorInside(selectedVisitor || '', changes.room, changes.area.selectedKey)}
                        />
                    </Stack>
                )}
                isFooterAtBottom={true}
            >
                <Stack tokens={{ childrenGap: 16 }} style={{ marginTop: 16 }}>
                    <DynamicForm formFields={formFields} onChange={onWaitingRoomFormChange}></DynamicForm>
                    {selectedArea && fetchedRooms.length === 0 && (
                        <Text style={{ fontWeight: 600, color: 'red' }}>{IvicosStrings.NoRoomAvailableDescription}</Text>
                    )}
                </Stack>
            </Panel>
        </>
    );
};
